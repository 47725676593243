import styled from 'styled-components';

export const InputFieldStyles = styled.div<{ disabled?: boolean }>`
  width: 100%;

  label {
    position: relative;
    display: block;
    width: 100%;

    span {
      position: absolute;
      top: 0;
      display: inline-block;
      width: 100%;
      font-size: var(--fs-3);
      font-weight: 400;
      line-height: 170%;
      color: #8c8e91;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 0.3s ease-out;

      &.focused,
      &.error {
        transform: translateY(-120%);
        font-size: var(--fs-5);
      }
    }

    input {
      width: 100%;
      padding: 0 0 8px;
      font-size: var(--fs-3);
      font-weight: 400;
      line-height: 170%;
      color: ${({ disabled }) => (disabled ? '#ccc' : '#000')};
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #000;
      outline: none;
      transition: 0.4s;
      caret-color: #ff3800;

      &.error {
        border-color: #ff3800;
      }

      &.password {
        padding-right: 43px;
      }
    }

    button {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      right: 0;
    }

    .hide {
      display: none;
    }

    .formErrorContainer {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding-top: 4px;
      font-size: var(--fs-5);
      line-height: 120%;
      color: #ff3800;
      text-align: left;
      word-wrap: break-word;
    }
  }
`;
