import { TChip } from './types';
import { ChipContainer } from './styles';
import React, { FC } from 'react';
export const Chip: FC<TChip> = ({
  text,
  id,
  onPress
}) => <ChipContainer key={id}>
    <span className="chip-text">{text}</span>
    <span onClick={onPress} className="chip-close">
      &times;
    </span>
  </ChipContainer>;