import styled from 'styled-components';

export const NumericScaleStyles = styled.div<{
  signatureColor: string | undefined;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 -10px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    overflow: hidden;

    button {
      width: 100%;
      display: grid;
      place-items: center;
      border-left: 1px solid #e5e5e5;

      &:first-child {
        border-left: none;
      }

      &.selected {
        background: #aaa;
        color: #fff;
      }
    }

    span {
      width: 20px;
      text-align: center;
    }
  }

  input {
    width: 200px;
  }

  .signatures {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: var(--fs-7);
    line-height: 120%;
    color: ${({ signatureColor }) => signatureColor || '#b4b4b4'};
  }
`;
