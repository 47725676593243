import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { DissatisfactionDashboardStyles } from './styles';
import { analyticsApi, ApiDissatisfactionsAnswer, ApiDissatisfactionsItem, TPostDissatisfaction } from '../../../../api';
import { Table } from '../../../organisms';
import { PieChart } from '../../analyticsCharts';
import { Loader } from '../../../atoms';
import { DataItem } from '../../../../pages';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { useWindowSize } from 'usehooks-ts';
import { extraFieldsSettings } from './utils';
type TDissatisfactionDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
  divisionData: string;
  divisionPie: string;
};
const colors: string[] = ['#6EAE93', '#A7C4E9', '#F4EF67', '#CFC4FF', '#F2C3DB', '#C3DBDB', '#FA7B4C', '#BB6685', '#BA806E'];
type TColorDictionary = {
  key: string;
  value: string;
};
const DissatisfactionDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "DissatisfactionDashboard_1",
  portals: ["v0"]
});
export const DissatisfactionDashboard: FC<TDissatisfactionDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions,
  divisionData,
  divisionPie
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiDissatisfactionsItem[]>([]);
  const [pie, setPie] = useState<ApiDissatisfactionsAnswer[]>([]);
  const [colorDictionary, setColorDictionary] = useState<TColorDictionary[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [division, setDivision] = useState<string>('');
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const {
    width: pageWidth,
    height: pageHeight
  } = useWindowSize();
  const dataCount = useRef<number>(0);
  const handleClick = (item: DataItem) => {
    setDivision(prev => !prev.length || prev !== item.label ? item.label : '');
  };
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery, division]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      sourceIDs,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostDissatisfaction = {
      from: from as string,
      to: to as string,
      division,
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      regionLevel: 3,
      filters: {
        regions: regions as number[],
        relatedByOrgans: relatedByOrgans as number[],
        cnapTypes: cnapTypes as string[],
        organTypes: organTypes as string[],
        idfs: idfs as string[]
      }
    };
    setLoading(true);
    Promise.all([analyticsApi.getDissatisfaction(+companyID, filteredData, divisionData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10), analyticsApi.getDissatisfactionPie(+companyID, divisionPie, filteredData)]).then(([data, pie]) => {
      if (data.data.data) {
        setData(prev => [...prev, ...data.data.data]);
      }
      dataCount.current = data.count ? data.count : 0;
      setPie(pie.data.answers);
      setColorDictionary(pie.data.answers.map((el, index) => ({
        key: el.label,
        value: colors[index]
      })));
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink(`analytics/${divisionData}/file`));
    dispatch(setSaveFilters({
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      from: from as string,
      sourceIDs: sourceIDs as number[],
      to: to as string,
      division,
      isDiia: isDiia as boolean
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'code',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.code}</span>
          </div>
    }, {
      Header: 'Регіон',
      accessor: 'regionName',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.regionName}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Назва центру</span>
          </div>,
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'Причини незадоволеності',
      accessor: 'answers',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            {data.row.original.answers.filter((el: any) => el.label).map((res: any) => <div key={res.label} className="rating-item">
                <div style={{
            background: colorDictionary.find(e => e.key === res.label)?.value || '#000'
          }} />
                <p>{res.count} - {res.label}</p>
              </div>)}
          </div>
    }]);
  }, [data, colorDictionary]);
  return /*@million jsx-skip*/<DissatisfactionDashboard_1 v0={<DissatisfactionDashboardStyles>
      <div className="chart">
        <div className="chart-pie">
          <PieChart width={dimensions.width / (pageWidth < 1440 ? 2 : 3.5)} height={dimensions.height / (pageWidth < 1440 ? 2 : 3.5)} data={pie.map((el, index) => ({
          label: el.label,
          value: el.percent,
          fill: el.label === division || !division.length ? colors[index] : `${colors[index]}33`,
          count: el.count
        }))} onClick={handleClick} chartLegend />
        </div>
        <div className="chart-legend">
          {pie.map((el, index) => <div key={el.label} className="chart-item">
              <div style={{
            background: colors[index]
          }} />
              <p>{el.label}</p>
            </div>)}
        </div>
      </div>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name', 'answers']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} extraFieldsSettings={extraFieldsSettings} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </DissatisfactionDashboardStyles>} />;
};