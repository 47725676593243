import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { InformationRespondentsDashboardStyles } from './styles';
import { useWindowSize } from 'usehooks-ts';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { analyticsApi, ApiGroupedNps, fieldsApi, TPostInformationRespondents, TPostGroupedNps, ApiInformationRespondents } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
import { PieChart } from '../../analyticsCharts';
const colors: string[] = ['#6EAE93', '#CFC4FF', '#F5EF67', '#FA7B4C', '#BB6685', '#BA806E'];
const sexColors: string[] = ['#A7C4E9', '#F2C3DB'];
type TPostInformationRespondentsDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
export const InformationRespondentsDashboard: FC<TPostInformationRespondentsDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiInformationRespondents | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      sourceIDs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostInformationRespondents = {
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      firstQuestion: 858866
    };
    setLoading(true);
    analyticsApi.geTPostInformationRespondents(+companyID, filteredData).then(res => {
      if (res.statusCode === 200) {
        setData(res.data);
        setLoading(false);
      }
    });
    dispatch(setSaveLink('analytics/information-respondents/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean,
      sourceIDs: sourceIDs as number[],
      firstQuestion: 858866
    }));
  }, [reQuery]);
  if (loading) {
    return <InformationRespondentsDashboardStyles>
        <Loader />
      </InformationRespondentsDashboardStyles>;
  }
  return <InformationRespondentsDashboardStyles>
      {data && <div className="charts">
          <div className="charts-item charts-one">
            <h4>Вікова категорія</h4>
            <div className="legend">
              {data.age.map((e, i) => <div className="legend-item">
                  <div style={{
              background: colors[i]
            }} />
                  <p>{e.name}</p>
                </div>)}
            </div>
            <div className="pies">
              <PieChart width={dimensions.width / 7} height={dimensions.height / 5} data={data.age.map((el, index) => ({
            label: el.name,
            value: el.percent,
            fill: colors[index],
            count: el.value
          }))} chartLegend />
            </div>
          </div>
          <div className="charts-item charts-two">
            <h4>Стать об’єкта звернення</h4>
            <div className="legend">
              <div className="legend-item">
                <div style={{
              background: sexColors[0]
            }} />
                <p>Чоловік</p>
              </div>
              <div className="legend-item">
                <div style={{
              background: sexColors[1]
            }} />
                <p>Жінка</p>
              </div>
            </div>
            <div className="pies">
              <PieChart width={dimensions.width / 7} height={dimensions.height / 5} data={data.sex.map((el, index) => ({
            label: el.name,
            value: el.percent,
            fill: sexColors[index],
            count: el.value
          }))} chartLegend />
            </div>
          </div>
          <div className="charts-item charts-three">
            <h4>Статус об’єкта звернення</h4>
            <div className="legend">
              {data.status.map((e, i) => <div className="legend-item">
                  <div style={{
              background: colors[i]
            }} />
                  <p>{e.name}</p>
                </div>)}
            </div>
            <div className="pies">
              <PieChart width={dimensions.width / 7} height={dimensions.height / 5} data={data.status.map((el, index) => ({
            label: el.name,
            value: el.percent,
            fill: colors[index],
            count: el.value
          }))} chartLegend />
            </div>
          </div>
          <div className="charts-item charts-four">
            <h4>CSI за категоріями вік і стать</h4>
            <div className="legend">
              <div className="legend-item">
                <div style={{
              background: sexColors[0]
            }} />
                <p>Чоловік</p>
              </div>
              <div className="legend-item">
                <div style={{
              background: sexColors[1]
            }} />
                <p>Жінка</p>
              </div>
            </div>
            <div className="bars">
              {data.sexAges.map((el, index) => <div key={el.name} className="bars-item">
                    <div className="bars-wrapper">
                      <div className="bars-fill">
                        <div style={{
                  background: sexColors[0],
                  height: `${Math.abs(el.mPercent)}%`
                }} />
                        <span>{el.mPercent.toFixed(1)}%</span>
                      </div>
                      <div className="bars-fill">
                        <div style={{
                  background: sexColors[1],
                  height: `${Math.abs(el.wPercent)}%`
                }} />
                        <span>{el.wPercent.toFixed(1)}%</span>
                      </div>
                    </div>
                    <h6 title={el.name}>{el.name}</h6>
                  </div>)}
            </div>
          </div>
          <div className="charts-item charts-five">
            <h4>CSI за категоріями статус і стать</h4>
            <div className="legend">
              <div className="legend-item">
                <div style={{
              background: sexColors[0]
            }} />
                <p>Чоловік</p>
              </div>
              <div className="legend-item">
                <div style={{
              background: sexColors[1]
            }} />
                <p>Жінка</p>
              </div>
            </div>
            <div className="bars">
              {data.sexStatus.map((el, index) => <div key={el.name} className="bars-item">
                    <div className="bars-wrapper">
                      <div className="bars-fill">
                        <div style={{
                  background: sexColors[0],
                  height: `${Math.abs(el.mPercent)}%`
                }} />
                        <span>{el.mPercent.toFixed(1)}%</span>
                      </div>
                      <div className="bars-fill">
                        <div style={{
                  background: sexColors[1],
                  height: `${Math.abs(el.wPercent)}%`
                }} />
                        <span>{el.wPercent.toFixed(1)}%</span>
                      </div>
                    </div>
                    <h6 title={el.name}>{el.name}</h6>
                  </div>)}
            </div>
          </div>
        </div>}
    </InformationRespondentsDashboardStyles>;
};