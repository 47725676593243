import styled from 'styled-components';

export const TextAreaStyles = styled.div`
  position: relative;

  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: var(--fs-2);
    line-height: 120%;
    color: rgb(0, 0, 0);
  }

  textarea {
    resize: none;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    height: 100%;
    font-family: e-Ukraine;
    font-size: var(--fs-3);
    box-sizing: border-box;
  }

  .count {
    position: absolute;
    color: #6c6c6c;
    font-size: var(--fs-5);
    bottom: -15px;
    right: 10px;

    &.error {
      color: red;
    }
  }
`;
