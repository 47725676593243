import styled from 'styled-components';

export const FieldSelectorStyles = styled.div`
  width: 100%;
  height: 36px;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  grid-gap: 12px;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: #e0e0e0;
  }

  p {
    font-size: var(--fs-5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
