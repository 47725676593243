import styled from 'styled-components';
export const FormPickerStyles = styled.div`
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;

    .modalContent {
      width: 100%;
    }

    .forms {
      box-sizing: border-box;
      max-height: 200px;
      position: relative;
      overflow: auto;

      &-list {
        display: flex;
        flex-direction: column;
      }

      &-item {
        padding: 8px 12px;
        cursor: pointer;
        font-size: var(--fs-3);

        &:hover {
          text-decoration: underline;
        }
        
        &:not(:last-child) {
          border-bottom: 1px solid rgb(229, 229, 229);
        }
      }
    }
  }
`;