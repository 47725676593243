import styled from 'styled-components';

export const AnalyticsFiltersStyles = styled.div<{ filters: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: absolute;
    border-radius: 5px;
    z-index: 10;
    right: 25px;
    overflow: hidden;
    padding: 45px 15px 25px;
    box-sizing: border-box;
    gap: 10px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease-in-out;
    transform: ${({ filters }) => (filters ? 'translateX(0%)' : 'translateX(120%)')};

    @media screen and (max-width: 1500px) {
        max-width: 270px;
    }

    .close-filters {
        position: absolute;
        cursor: pointer;
        right: 15px;
        top: 10px;
        transform: rotate(45deg);
    }

    .filter-wrapper {
        display: flex;
        position: relative;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        gap: 10px;
    }
`;
