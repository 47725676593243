import styled from 'styled-components';

export const AddRoleStyles = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .titleContainer {
    .buttonsContainer {
      display: flex;

      & > div {
        margin-left: 24px;
        min-width: 100px;

        & > button {
          width: 100%;
        }
      }
    }
  }

  form {
    flex: 1;
    display: flex;
    max-height: calc(100% - 64px);

    .formSection {
      padding: 32px 16px 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 15px;

      .labelContainer {
        display: flex;
        margin-bottom: 8px;

        .fakeLabel {
          font-weight: 500;
          font-size: var(--fs-2);
          line-height: 120%;
          color: #000000;
        }
      }

      h2 {
        margin-bottom: 17px;
      }

      .twoColumns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        width: 100%;
        max-width: 524px;
      }

      .selectedPermissions {
        margin-bottom: 24px;

        .permission {
          width: 100%;
          max-width: 300px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  .checkboxContainer {
    display: grid;
    gap: 8px;

    .checkboxWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .checkbox {
      border: 1px solid #e5e5e5;
      border-radius: 2px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &Selected {
        border: 1px solid #000;
      }
    }
  }

  .editButtonsContainer {
    .menuOpenerContainer {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .menuOpenerButton {
        padding: 0 20px;
        cursor: pointer;
      }

      .menu {
        position: relative;

        .menuList {
          z-index: 99;
          position: absolute;
          top: calc(50% + 15px);
          right: 16px;
          width: 260px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          overflow: hidden;
          filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

          list-style: none;
          font-size: var(--fs-3);
          color: #000000;

          li {
            height: 41px;
            background: #ffffff;
            border-bottom: 1px solid #e5e5e5;
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }
          }

          button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            transition: background-color 0.3s;

            &:hover {
              background-color: #f0f5f5;
              transition: background-color 0.3s;
            }
          }

          span {
            margin-left: 12px;
          }
        }
      }
    }

    .modalBody {
      .modalButtonsWrap {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .modalButtonsCaption {
          margin-bottom: 16px;
          font-size: var(--fs-3);
          color: #000000;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      .modalError {
        font-size: var(--fs-5);
        line-height: 120%;
        color: #ff3333;
        margin-bottom: 0;
      }
    }
  }
`;
