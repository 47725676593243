import styled from 'styled-components';

export const SquareCheckboxStyles = styled.div`
  .fieldName {
    font-weight: 500;
    font-size: var(--fs-3);
    line-height: 120%;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .checkboxDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .checkboxWrapper {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 8px;
    cursor: pointer;
  }

  .checkboxText {
    line-height: 150%;
    font-size: var(--fs-4);
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: start;
  }

  .formErrorContainer {
    height: 24px;
    font-size: var(--fs-5);
    line-height: 120%;
    color: #ff3333;
  }
`;
