export const extraFieldsSettings = {
  code: {
    width: '10%',
  },
  name: {
    width: '30%',
  },
  regionName: {
    width: '20%',
  },
  answers: {
    width: '40%',
  },
};
