import styled from 'styled-components';

export const SquareCheckboxStyles = styled.div`
  .fieldName {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .checkboxDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .checkboxWrapper {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 8px;
    cursor: pointer;
  }

  .checkboxText {
    /* padding: 10 10px; */
    line-height: 22px;
    font-size: 14px;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis ellipsis;
    justify-content: center;
    align-items: center;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
