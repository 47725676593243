import styled from 'styled-components';

export const FormContentStyles = styled.div`
   width: 100%;
   display: flex;
   flex: 1;
   gap: 2px;
   height: 100%;
   overflow: hidden;

   .localization {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      background: rgb(231, 238, 243);
      border-right: 1px solid rgb(229, 229, 229);
      width: 100%;
      flex-shrink: 0;

      &-list {
         display: flex;
         flex-direction: column;
         gap: 5px;
         padding: 5px;
      }

      &-item {
         cursor: pointer;
         display: flex;
         align-items: center;
         gap: 25px;
         height: 50px;
         padding: 12px 16px;
         border-bottom: 1px solid rgb(229, 229, 229);
         background: rgb(255, 255, 255);
         position: relative;

         &-selected {
            box-shadow: inset 0 0 0 3px rgb(229, 229, 229);
         }

         &-del {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 16px;
         }
      }

      &-add {
         margin-top: 5px;
         cursor: pointer;
         text-align: center;
         padding: 12px 16px;
         border: 2px dashed rgb(128, 128, 128);
         font-size: var(--fs-3);
      }
   }

   .header {
      padding: 12px 8px;
      width: 100%;
      border-bottom: 1px solid rgb(229, 229, 229);
      background: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      height: 50px;

      h6 {
         font-size: var(--fs-3);
      }
   }
`;
