import styled from 'styled-components';

export const DocumentsStyles = styled.div`
  .documentsWrapper {
    .tableTitleWrapper {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 32px 0 16px;
    }

    .editButtonsContainer {
      height: 100%;
      display: flex;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
      }

      button {
        display: flex;
        align-items: center;
      }
    }

    .editButtons {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .documentsTableContainer {
    border-top: 1px solid #f0f0f0;
  }

  .modalBody {
    .modalButtonsWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: var(--fs-3);
        color: #000000;
      }

      div {
        display: flex;
        justify-content: space-between;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: var(--fs-3);
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;

        :nth-child(1) {
          background: #ffffff;
          border: 1px solid #c4c4c4;
          color: #000000;

          :hover {
            color: #fff;
            background: #c4c4c4;
          }
        }

        :nth-child(2) {
          border: 1px solid #ff0101;
          background: #ff0101;
          color: #ffffff;

          :hover {
            background: #ffffff;
            color: #ff0101;
          }
        }
      }
    }

    .modalError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
