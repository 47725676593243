import styled from 'styled-components';

export const InfoAlertStyles = styled.div`
  width: 34px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover .infoText {
    display: block;
  }

  .infoIcon {
    width: 16px;
    height: 16px;
    border-radius: 12px;
    border: 1px solid #000;
    display: grid;
    place-items: center;

    svg {
      width: 12px;
      height: 12px;
      filter: invert(42%) sepia(68%) saturate(490%) hue-rotate(125deg)
        brightness(96%) contrast(99%);
    }
  }

  .infoText {
    display: none;
    width: 280px;
    padding: 16px;
    position: absolute;
    right: -8px;
    top: 38px;
    z-index: 10;
    font-size: var(--fs-3);
    color: #000;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  }
`;
