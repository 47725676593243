import React, { FC } from 'react';
export const ArrowDownIcon: FC<{
  width?: number;
  height?: number;
  color?: string;
}> = React.memo(({
  width = 36,
  height = 36,
  color = '#0C0005'
}) => <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 22L13.6699 16L22.3301 16L18 22Z" fill="#0C0005" />
  </svg>);