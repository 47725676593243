import styled from 'styled-components';
export const StatusesSettingsStyles = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    gap: 7px;
    display: flex;
    align-items: center;
    font-size: var(--fs-3);

    svg {
      flex-shrink: 0;
    }
  }
`;