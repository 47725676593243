import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ApiCategory, categoryApi, formApi } from '../../../../api';
import { DeepArrayItem } from '../../../../entities';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { getNodeNamesArray, getNodesDeepArray } from '../../../../utils';
import { Loader } from '../../../atoms';
import MultiSelectWithChildren from '../../../form/customSelect/multiSelectWithChildren/MultiSelectWithChildren';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { TransparentButton } from '../../../atoms/transparentButton/TransparentButton';
import { resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { ICategoryFilter, CategoryFilterItem, CategoryFilterValues } from './additionalSettings';
import { CategoryFilterStyles } from './CategoryFilterStyles';
import { getAllSelectedNodeIds, getParentId, getPathForInitialList, handleSelectNode, markAllNodesWithAllSelectedChildren } from './utils';
interface DeepCategory {
  id: number;
  name: string;
  deep: number;
  parentId: number | undefined;
}
function convertToDeepCategory(apiCategories: ApiCategory[]): DeepCategory[] {
  const findParent = (parentId: number | undefined): number | undefined => {
    const parent = apiCategories.find(category => category.id === parentId);
    if (parent) {
      return findParent(parent.parentID);
    }
    return parentId;
  };
  return apiCategories.map(category => ({
    id: category.id,
    name: category.name,
    deep: category.parentID ? 1 : 0,
    parentId: findParent(category.parentID)
  }));
}
export const CategoryFilter = React.memo(({
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  resizable,
  containerExtraStyles,
  extraFilterFormStyles,
  optionsExtraStyles
}: ICategoryFilter) => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CategoryFilterValues>();
  const ref = useRef<any>();
  const formRef = useRef<FormikProps<CategoryFilterValues>>(null);
  const categoriesRef = useRef<DeepArrayItem[]>([]);
  const categoryNamesRef = useRef<CategoryFilterItem[]>([]);
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        formRef.current?.submitForm();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  async function onSubmit(values: CategoryFilterValues, {
    setSubmitting
  }: FormikHelpers<CategoryFilterValues>) {
    const params: {
      [key: string]: number | string | any;
    } = {};
    const selectedCategoryIds: number[] = [];
    if (values.category) {
      getAllSelectedNodeIds(selectedCategoryIds, values.category);
    }
    if (selectedCategoryIds.length > 0) {
      params.categories = selectedCategoryIds;
    } else if (filterParams?.categories && selectedCategoryIds.length === 0) {
      delete filterParams.categories;
    }
    const resultParams: {
      [key: string]: any;
    } = {};
    Object.entries({
      ...filterParams,
      ...params
    }).forEach(item => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });
    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }
  const additionalResetHandlers = () => {
    if (filterParams.categories) {
      delete filterParams.categories;
    }
    setFilterParams({
      ...filterParams
    });
    setApply({
      ...filterParams
    });
    setReQuery();
  };
  async function getNodesBySelectedCompany(companyId: number) {
    setLoadingDataByCompany(true);
    const categories = await categoryApi.getFlatCategoriesByCompany(companyId);
    if (categories.statusCode >= 200 && categories.statusCode < 300) {
      let categoryNamesArray: DeepCategory[] = [];
      categoryNamesArray = convertToDeepCategory(categories.data);
      categoryNamesArray.map((item, index) => {
        if (item.deep > 0) {
          item.parentId = getParentId(categoryNamesArray, item.deep, index);
        }
        return item;
      });
      categoryNamesRef.current = categoryNamesArray.map(item => ({
        ...item,
        selected: false,
        hasSelectedChildren: false,
        allChildrenAreSelected: false
      }));
      categoriesRef.current = getNodesDeepArray(categoryNamesArray);
    }
    setLoadingDataByCompany(false);
  }
  useEffect(() => {
    getNodesBySelectedCompany(+filterParams.companyID).then(res => {
      const categoriesArray = filterParams?.categories && filterParams.categories.length > 0 ? categoryNamesRef.current?.map(category => filterParams?.categories?.includes(category.id) ? {
        ...category,
        selected: true
      } : category) : categoryNamesRef.current;
      function findParentNode(array: CategoryFilterItem[], item: CategoryFilterItem) {
        const parentCategoryIndex = array.findIndex(category => category.id === item.parentId);
        if (parentCategoryIndex !== undefined) {
          array[parentCategoryIndex].hasSelectedChildren = true;
          if (array[parentCategoryIndex].deep !== 0 && !array[parentCategoryIndex - 1].hasSelectedChildren) {
            findParentNode(array, array[parentCategoryIndex]);
          }
        }
      }
      categoriesArray.forEach(item => {
        if (item.selected && item.deep !== 0) {
          findParentNode(categoriesArray, item);
        }
      });
      const deepCategorys = getNodesDeepArray(categoriesArray);
      markAllNodesWithAllSelectedChildren(deepCategorys);
      setInitialValues({
        category: deepCategorys
      });
    });
  }, []);
  const renderForm = ({
    values,
    handleChange,
    setFieldValue,
    resetForm
  }: FormikProps<CategoryFilterValues>) => <Form className={extraFilterFormStyles}>
        <div className={containerExtraStyles}>
          <SubmitButton extraBlockStyles={{
        width: '100%',
        maxWidth: 270
      }} extraButtonStyles={{
        width: '100%',
        height: 32,
        fontSize: 'var(--fs-2)',
        fontWeight: 500
      }}>
            {translations[interfaceLanguage].apply_filters_button}
          </SubmitButton>
          <TransparentButton handleClick={() => {
        resetForm();
        additionalResetHandlers();
      }} text={translations[interfaceLanguage].reset_reviews_filter} extraStyles={resetFilterButtonStyles} extraButtonTextStyles={{
        color: '#ffffff',
        fontSize: 'var(--fs-2)',
        fontWeight: 500
      }} extraContainerStyles={{
        width: '100%',
        maxWidth: 270
      }} />
          <div className="selectContainer">
            <MultiSelectWithChildren
        // @ts-ignore
        showLastElement name="category" options={values.category || []} selectKey="name" handleSelect={(value, path, filtered) => {
          const changedNodesData = [...(values.category || [])];
          const pathForInitialList: number[] = [];
          if (filtered) {
            getPathForInitialList(pathForInitialList, value, categoryNamesRef.current, values.category!);
          }
          handleSelectNode(changedNodesData, value, filtered ? pathForInitialList : path || [], {
            hasSelectedChildren: value.children.length > 0 && !value.hasSelectedChildren,
            allChildrenAreSelected: value.children.length > 0 && !value.selected
          });
          setFieldValue('category', changedNodesData);
        }} search required hideError isLoading={loadingDataByCompany} disabled={!categoriesRef.current.length} optionsContainerStyles={optionsExtraStyles} />
          </div>
        </div>
      </Form>;
  return <CategoryFilterStyles ref={ref} style={resizable ? {
    overflow: 'auto',
    resize: 'both'
  } : {}}>
        {initialValues ? <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
            {renderForm}
          </Formik> : <Loader margin={0} />}
      </CategoryFilterStyles>;
});