import styled from 'styled-components';

export const AddPermissionStyles = styled.div`
  width: 100%;

  .titleContainer {
    .buttonsContainer {
      display: flex;

      & > div {
        margin-left: 24px;
        min-width: 100px;

        & > button {
          width: 100%;
        }
      }
    }
  }

  .permissionsContainer {

    .sectionTitle {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: var(--fs-2);
      line-height: 120%;
      color: #000000;

      .required {
        color: #ff0101;
      }
    }

    .permissions {
      margin-top: 8px;
      display: grid;
      grid-gap: 8px;
    }
  }

  .formSection {
    padding: 20px 32px 32px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 35px;

    .labelContainer {
      display: flex;
      margin-bottom: 8px;

      .fakeLabel {
        font-weight: 500;
        font-size: var(--fs-2);
        line-height: 120%;
        color: #000000;
      }
    }

    h2 {
      margin-bottom: 17px;
    }

    .twoColumns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      width: 100%;
      max-width: 524px;
    }

    .selectedPermissions {
      margin-bottom: 24px;

      .permission {
        width: 100%;
        max-width: 300px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
