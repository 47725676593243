import styled from 'styled-components';

export const TextAreaStyles = styled.div`
  position: relative;

  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: var(--fs-2);
    line-height: 120%;
    color: rgb(0, 0, 0);
  }

  textarea {
    resize: none;
    padding: 12px;
    width: 100%;
    height: 100%;
    font-size: var(--fs-3);
    font-weight: 400;
    line-height: 170%;
    color: #8c8e91;
    color: rgb(0, 0, 0);
    box-sizing: border-box;
    border: 2px solid #000;

    &::placeholder {
      font-size: var(--fs-2);
      font-weight: 400;
      line-height: 170%;
      color: #8c8e91;
    }
  }
`;
