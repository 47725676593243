import { isArray } from 'lodash';
import { TOption } from './types';

export const mapFiltersBy = (filters: {
  [key: string]: TOption[] | string;
}) => {
  const mappedFilters: {
    [key: string]: number[] | string[] | string | number;
  } = {};
  Object.keys(filters).forEach((filterKey) => {
    if (isArray(filters[filterKey])) {
      mappedFilters[filterKey] = (filters[filterKey] as any[])?.map(
        (e) => e.id,
      );
    } else {
      mappedFilters[filterKey] = filters[filterKey] as string | number;
    }
  });
  console.log(mappedFilters);
  return mappedFilters;
};
