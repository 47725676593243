import styled, { css } from 'styled-components';

export const NameCustomFieldStyles = styled.div`
  width: 100%;

  .customNameWrapper {
    width: 100%;
  }
  &.onClick {
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;
