import styled from 'styled-components';

export const ScaleFieldStyles = styled.footer`
  margin-bottom: 42px;

  .body {
    padding: 24px 16px 0 16px;
    box-sizing: border-box;

    .inputContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }

    .inputRaw {
      display: grid;
      grid-template-columns: 1fr auto;
      //grid-gap: 16px;

      input {
        height: 40px;
        font-size: var(--fs-3);
        color: #000;
        background-color: #ffffff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;

        &::placeholder {
          font-size: var(--fs-3);
          color: #6c6c6c;
        }

        &.disabled {
          background-color: #fafafa;
        }
      }

      .infoContainer {
        width: 34px;
        height: 40px;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover .infoText {
          display: block;
        }

        .infoIcon {
          width: 16px;
          height: 16px;
          border-radius: 12px;
          border: 1px solid #000;
          display: grid;
          place-items: center;

          svg {
            width: 12px;
            height: 12px;
            filter: invert(42%) sepia(68%) saturate(490%) hue-rotate(125deg)
              brightness(96%) contrast(99%);
          }
        }

        .infoText {
          display: none;
          width: 280px;
          padding: 16px;
          position: absolute;
          right: -8px;
          top: 38px;
          z-index: 10;
          font-size: var(--fs-3);
          color: #000;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
        }
      }
    }

    .openSettingsPageButtonContainer {
      margin-bottom: 24px;
    }
  }

  .signatureContainer {
    h3 {
      font-size: var(--fs-2);
      margin-bottom: 16px;
    }

    .signatureValueContainer {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 16px;
      align-items: center;

      span {
        font-size: var(--fs-3);
        margin-bottom: 24px;
      }
    }
  }

  .radioFieldContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 16px;
    margin-bottom: 24px;
  }

  .fieldName {
    font-weight: 500;
    font-size: var(--fs-2);
    line-height: 120%;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: var(--fs-5);
    line-height: 120%;
    color: #ff3333;
  }
`;
