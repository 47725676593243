import styled from 'styled-components';

export const FormLocationsStyles = styled.div`
   padding: 12px 16px;
   max-width: 600px;
   flex: 1;
   box-sizing: border-box;
   position: relative;
   overflow: auto;

   .selectField {
      margin-top: 25px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 16px;
      position: relative;
      cursor: pointer;

      .selectedValue {
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;

         .arrowDownActive {
            transform: rotate(180deg);
         }
      }

      .valueContainer {
         width: 100%;
         display: grid;
         grid-template-columns: 1fr auto;
         grid-gap: 20px;
         align-items: center;
         font-size: var(--fs-3);
         color: #000;

         &.withExtraItem {
         grid-template-columns: auto 1fr auto;
         }
      }

      .placeholder {
         font-size: var(--fs-3);
         color: #6c6c6c;
      }

      label {
         font-size: var(--fs-3);
         line-height: 120% !important;
         padding: 0;
         margin: 0;
      }

      .emoji {
         display: flex;
      }
  }

   .loading {
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(230, 230, 230, 0.7);
      display: grid;
      place-items: center;
      border-radius: 4px;
   }

   .options {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%);
      z-index: 100;
      margin-bottom: 24px;
      width: 100%;
      overflow-y: auto;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      max-height: 350px;

      & > .option:last-child .row {
         border-bottom: none;
      }
   }

   .option {
      .row {
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         border-bottom: 1px solid #c4c4c4;
         box-sizing: border-box;
      }

      input {
         display: none;
         height: 32px;
      }

      button {
         width: 100%;
         height: 100%;
         font-size: var(--fs-3);
         display: grid;
         grid-template-columns: 1fr;
         grid-gap: 20px;

         .labelContent {
         display: grid;
         align-items: center;
         text-align: left;
         padding-left: 16px;
         width: 100%;
         height: 100%;
         color: #6e6e6e;
         box-sizing: border-box;
         }

         &.withImage .labelContent {
         grid-template-columns: auto 1fr;
         grid-gap: 8px;
         }

         &.expandButton {
         align-items: center;
         width: 24px;
         }
      }
   }

   .searchWrapper {
      height: 32px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #c4c4c4;
      box-sizing: border-box;
      padding: 0 16px;
      background-color: #fcfcfc;

      input {
         flex-grow: 1;
         margin-left: 12px;
         padding: 0;
         border: none;
         outline: none;
         font-size: var(--fs-3);
         color: #000;
         background-color: #fcfcfc;

         &::placeholder {
         font-size: var(--fs-3);
         color: #6e6e6e;
         }
      }
   }
`;
