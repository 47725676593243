import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ChartSelectorStyles } from './styles';
import { TGroup, TTab } from '../../../pages';
import { useClickOutside, usePermissionView } from '../../../hooks';
import { ArrowLeft } from '../../../assets';
import { truncateString } from '../../../utils';
type TChartSelector = {
  charts: TTab[];
  setSelectedTab: Dispatch<SetStateAction<string>>;
  group: TGroup;
  selectedTab: string;
};
export const ChartSelector: FC<TChartSelector> = ({
  charts,
  setSelectedTab,
  group,
  selectedTab
}) => {
  const hasAnyAccess = usePermissionView();
  const [dashboards, setDashboards] = useState<TTab[]>([]);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const handleNext = () => {
    const currentIndex = dashboards.findIndex(dashboard => dashboard.key === selectedTab);
    if (currentIndex < dashboards.length - 1) {
      setSelectedTab(dashboards[currentIndex + 1].key);
    }
  };
  const handlePrev = () => {
    const currentIndex = dashboards.findIndex(dashboard => dashboard.key === selectedTab);
    if (currentIndex > 0) {
      setSelectedTab(dashboards[currentIndex - 1].key);
    }
  };
  const handleChange = (key: string) => {
    setSelectedTab(key);
    setOpen(false);
  };
  useEffect(() => {
    setDashboards(charts.filter(tab => hasAnyAccess(tab.key).view && group.dashboards.includes(tab.position)));
  }, [selectedTab, group]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);
  console.log(dashboards.find(el => el.key === selectedTab));
  return <ChartSelectorStyles>
      <button type="button" className="chart-prev" onClick={handlePrev} disabled={dashboards.findIndex(d => d.key === selectedTab) === 0} aria-label="Обрати попередній дашборд">
        <ArrowLeft height={30} width={30} />
      </button>
      <div className="chart-selector">
        {/* {open && (
          <div className="chart-options">
            {dashboards.map((dashboard) => (
              <button
                type="button"
                className="chart-option"
                aria-label={dashboard.text}
                onClick={() => handleChange(dashboard.key)}
              >
                {truncateString(dashboard.text, 35)}
              </button>
            ))}
          </div>
         )}
         <div
          className="chart-name"
          onClick={() => setOpen(!open)}
          ref={selectedValueFieldRef}
          aria-label={dashboards.find((el) => el.key === selectedTab)?.text || ''}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
         >
          {truncateString(dashboards.find((el) => el.key === selectedTab)?.text || '', 30)}
         </div> */}
        <div className="chart-position">
          {dashboards.findIndex(el => el.key === selectedTab) + 1}
          {/* {dashboards.find((el) => el.key === selectedTab)?.position} */}
        </div>
      </div>
      <button type="button" className="chart-next" onClick={handleNext} disabled={dashboards.findIndex(d => d.key === selectedTab) === dashboards.length - 1} aria-label="Обрати наступний дашборд">
        <ArrowLeft height={30} width={30} />
      </button>
    </ChartSelectorStyles>;
};