import styled from 'styled-components';

export const RadioButtonStyles = styled.div`
  .fieldName {
    font-weight: 500;
    font-size: var(--fs-2);
    line-height: 120%;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .radioDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .radioWrapper {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 12px;
    cursor: pointer;
  }

  .customizedRadio {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 11px;
    box-sizing: border-box;
  }

  .customizedRadioChecked {
    border: 1px solid #000;
  }

  .customizedRadioCheckedDisabled {
    border: 1px solid #e5e5e5;
  }

  .checked {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: #000;
  }

  .innerRoundDisabled {
    background-color: #e5e5e5;
  }

  .radioText {
    line-height: 150%;
    font-size: var(--fs-3);
  }

  .formErrorContainer {
    height: 24px;
    font-size: var(--fs-5);
    line-height: 120%;
    color: #ff3333;
  }
`;
