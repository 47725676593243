import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { ReviewsTableStyles, SearchContainer, DropDownContainer, StyledSelect } from './styles';
import { ApiResponse, companyApi, companySettingsApi, reviewApi, authApi, formApi, exportApi, rolesApi } from '../../../api';
import { getNodesDictionary, getLanguages, addKeyQuestionValueToAnswer } from '../../../utils';
import { INode, IReviewForm, CompaniesMainInfo, IContact, IReviewStatus, ISource } from '../../../entities';
import 'react-calendar/dist/Calendar.css';
import { ConnectionChannelExtraField, KeyAnswerExtraField, LanguagesExtraField, NameExtraField, StatusExtraField, SourceExtraField, EmailExtraField, UserNameExtraField, PhoneNumberExtraField, CommentExtraField, KeyQuestionAnswerField, TagsField, CategoryField, AttachmentField, SentimentField } from './ReviewsTableFields';
import { useAppDispatch, useAppSelector, setCompanyMetadata, setUser, setSidebarFullWidth } from '../../../state';
import { availablePageSizes, hiddenReviewFilterStatuses, networkIcon, reviewsStatusIconsAndText } from '../../../constants';
import { useWindowSize } from 'usehooks-ts';
import { Helmet } from 'react-helmet-async';
import { DropDownKeys, extraFieldsSettings, mapAnswers, mapTableSettingsKeyNames, options, TDropDownValue } from './utils';
import { CompanyFilter, CustomSelect, FilterSelectInput, Loader, StatusesCounter, SubHeader, Table, TransparentButton } from '../../../components';
import { useDebounce } from '../../../hooks';
import { ClearFilters, ReviewStatuses, SaveReviews, SearchIcon } from '../../../assets';
import { parseSearchString } from '../../../utils/parseSearchString';
import { Expandable } from '../../../components/atoms/expandable/index';
import { TQuestion } from '../../../components/molecules/reviewsTableFilters/scoreFilter/TScoreFilter';
import download from 'downloadjs';
import { CustomFilter } from '../../../components/molecules/customFilter';
import { TOption } from '../../../components/molecules/customFilter/types';
import { mapFiltersBy } from '../../../components/molecules/customFilter/utils';
const filterKeys = ['created_at', 'statuses', 'nodes', 'sources', 'keyAnswer', 'keyQuestion', 'communication_channel', 'categories', 'tags', 'attachments'];
export const ReviewsTable = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const code = search.get('companyID');
  const {
    width
  } = useWindowSize();
  const {
    languages: languagesState,
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data
  } = useAppSelector(state => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingReviews, setLoadingReviews] = useState<boolean>(false);
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(availablePageSizes[1]);
  const [selectedCategoryRow, setSelectedCategoryRow] = useState<string | undefined>(undefined);
  const [selectedTagRow, setSelectedTagRow] = useState<string | undefined>(undefined);
  const [searchField, setSearchField] = useState<string>('');
  const [selectedType, setSelectedType] = useState<TDropDownValue>(options[0]);
  const [headers, setHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [clientStatuses, setClientStatuses] = useState<number[]>([]);
  const [additionalFilters, setAdditionalFilters] = useState<boolean>(false);
  const [statusesCounts, setStatusesCounts] = useState<boolean>(false);
  const nodesRef = useRef<{
    [key: string]: Partial<INode>;
  }>({});
  const formsRef = useRef<{
    [key: string]: {
      lang: number;
      companyId: number;
      nodeId: number;
    };
  }>({});
  const reviewsRef = useRef<IReviewForm[]>();
  const reviewsQuantityRef = useRef<number>(0);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[] | TOption[];
  }>({});
  const tableColumnsSettingsRef = useRef<{
    [key: string]: boolean;
  }>({});
  const sourcesRef = useRef<{
    reviewId: number;
    value: string | null | undefined;
  }[]>([]);
  const reviewContactsRef = useRef<Array<IContact[]>>([]);
  const filterQuestions = useRef<TQuestion[]>([]);
  const debouncedSearchField = useDebounce(searchField, 1500);
  const reviewStatuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter(item => !hiddenReviewFilterStatuses.includes(item?.value));
  const navigateToFilterParams = (params: {
    [key: string]: TOption[] | string;
  }) => {
    const path = createSearchParams(mapFiltersBy(params) as Record<string, string | string[]>);
    navigate(`/reviews/page/1?${path}`);
    setSearchParams(path);
  };
  const onClickToRow = async (data: any) => {
    window.open(`/reviews/company/${code}/review/${data.id}`, '_blank');
  };
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;
      return acc;
    }, {});
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: compID
      };
      navigateToFilterParams(filterParamsRef.current as {
        [key: string]: string | TOption[];
      });
      // const path = createSearchParams(
      //   filterParamsRef.current as Record<string, string | string[]>,
      // );
      // navigate(`/reviews/page/1?${path}`);
      setReQuery(!reQuery);
    }
  };
  const getCompanyNodes = async (id: number) => {
    const res = await formApi.getCompanyNodes(id);
    if (res.statusCode >= 200 && res.statusCode < 300) {
      const newNodesDictionary: {
        [key: number]: Partial<INode>;
      } = {};

      // @ts-ignore
      getNodesDictionary(newNodesDictionary, res.data);
      const nodesDictionary = nodesRef.current ? {
        ...nodesRef.current,
        ...newNodesDictionary
      } : newNodesDictionary;
      if (Object.keys(nodesDictionary).length > 0) {
        // @ts-ignore
        nodesRef.current = nodesDictionary;
      }
    }
  };
  function getReviewsAdditionalData(item: IReviewForm, source: ISource) {
    return {
      reviewId: item.id,
      value: source ? source.name : ''
    };
  }
  function transformReviewAnswers(review: any) {
    const reviewAnswersWithType = review.answers.map((item: any) => ({
      ...item,
      type: item.field.type,
      keyQuestion: addKeyQuestionValueToAnswer(item)
    }));
    return {
      ...review,
      answers: reviewAnswersWithType
    };
  }
  async function workWithResponse(response: ApiResponse<IReviewForm[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const reviewsModified = response.data.map(transformReviewAnswers);
      const data = [...(reviewsRef.current || []), ...(Array.isArray(reviewsModified) ? reviewsModified : [])];
      reviewsRef.current = data;
      reviewsQuantityRef.current = response.count!;
      if (tableColumnsSettingsRef.current?.sources) {
        sourcesRef.current = response.data.map(item => getReviewsAdditionalData(item, item.source as ISource));
      }
      if (tableColumnsSettingsRef.current?.communication_channel) {
        reviewContactsRef.current = response.data.map(item => item.contacts);
      }

      // const path = createSearchParams(
      //   filterParamsRef.current as Record<string, string | string[]>,
      // );

      // setSearchParams(path);
      setLoadingReviews(false);
    }
  }
  const resetFilterQuestions = () => {
    filterQuestions.current = [];
  };
  const applyFilters = (params: any) => {
    filterParamsRef.current = params;
    if (params.questions) {
      filterQuestions.current = params.questions;
      delete params.questions;
    }
    setReQuery(e => !e);
    navigateToFilterParams(params);
    // const path = createSearchParams(
    //   mapFiltersBy(params) as Record<string, string | string[]>,
    // );
    // navigate(`/reviews/page/1?${path}`);
  };
  const refreshFilter = () => {
    filterParamsRef.current = {
      companyID: filterParamsRef.current?.companyID
    };
    filterQuestions.current = [];
    const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
    setSearchParams(path);
    setReQuery(!reQuery);
  };
  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
  };
  const downloadExcel = () => {
    const newFilterParams = parseSearchString(searchParams);
    if (newFilterParams.companyID) {
      exportApi.exportReviews({
        ...newFilterParams,
        search: {
          [selectedType.name]: debouncedSearchField
        } as {
          clientID: string;
          phone: string;
          comment: string;
          [key: string]: any;
        }
      }).then(res => {
        const filename = 'review.xlsx';
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        download(res.data as Blob, filename, mimeType);
      });
    }
  };
  useEffect(() => {
    if (!languagesState) {
      getLanguages(dispatch);
    }
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
        dispatch(setSidebarFullWidth(false));
      }
    });
  }, []);
  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      getCompanyNodes(+filterParamsRef.current.companyID);
      companySettingsApi.getCompanySettings(+filterParamsRef.current.companyID).then(sett => {
        setClientStatuses(sett.data['client-statuses'] || []);
      });
    }
    const interval = setInterval(() => {
      if (filterParamsRef.current.companyID === 110) {
        setReQuery(!reQuery);
      }
    }, 180000);
    return () => clearInterval(interval);
  }, [filterParamsRef.current.companyID, reQuery]);
  const fetchRoles = async (limit: number, offset: number, q: string) => {
    const res = await rolesApi.getRoles(offset, limit, q);
    return {
      count: res.count ?? 0,
      items: res.data.map(e => ({
        id: e.id?.toString() ?? '0',
        name: e.name,
        value: false
      }))
    };
  };
  const networks: string[] = ['email', 'phone', 'telegram', 'viber', 'whatsapp'];
  const initialChanell = networks.map(network => ({
    id: network,
    key: network,
    name: getTranslationByLangOrEng(interfaceLanguage, `${network}_network`),
    value: false,
    icon: networkIcon(network)
  }));
  const statusesFormattedArray = reviewStatuses?.map(item => ({
    id: item.value,
    name: item.text,
    icon: item.icon,
    value: false
  }));
  useEffect(() => {
    if (formsRef.current && reviewsRef.current && tableColumnsSettingsRef.current) {
      const data = [...(tableColumnsSettingsRef.current?.reviewId ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_review_id_column_title'),
        accessor: 'reviewId',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <div>{row.original.id}</div>
      }] : []), ...(tableColumnsSettingsRef.current?.created_at ? [{
        Header: <FilterSelectInput filtersOpenerStyles={{
          minWidth: 220
        }} columnName="createdAt" title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_create')} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'createAt'
      }] : []), ...(tableColumnsSettingsRef.current?.statuses ? [{
        Header: <CustomFilter resizable dropdownWidth={350} title={getTranslationByLangOrEng(interfaceLanguage, 'table_columns_settings_status_option')} name="statuses" initialValues={statusesFormattedArray} filterParams={filterParamsRef.current as any} applyFilters={params => {
          applyFilters({
            ...filterParamsRef.current,
            ...params
          });
        }} />,
        accessor: 'statuses',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <StatusExtraField status={row.original.status} lang={interfaceLanguage} />
      }] : []), ...(tableColumnsSettingsRef.current?.nodes ? [{
        Header: <FilterSelectInput onlyEndingNodes title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_node_column_title')} columnName="nodes" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'nodeName',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameExtraField nodeName={row.original.node.name || ''} />
      }] : []), ...(tableColumnsSettingsRef.current?.sources ? [{
        Header: <FilterSelectInput title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_source')} columnName="sources" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'sources',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <SourceExtraField reviewId={row.original.id} sources={sourcesRef.current} />
      }] : []), ...(tableColumnsSettingsRef.current?.keyAnswer ? [{
        Header: <FilterSelectInput title={`${getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_estimate')}`} columnName="keyAnswer" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'keyAnswer',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <KeyAnswerExtraField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.keyQuestion ? [{
        Header: <FilterSelectInput filterWidth={250} title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_key_question_column_title')} columnName="csi" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'keyQuestion',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <KeyQuestionAnswerField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.communication_channel ? [{
        Header: <CustomFilter resizable dropdownWidth={350} filterParams={filterParamsRef.current as any} applyFilters={params => {
          applyFilters({
            ...filterParamsRef.current,
            ...params
          });
          // app;
        }} title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_contact_type')} name="contacts" initialValues={initialChanell} />
        // <FilterSelectInput
        //   columnName="contacts"
        //   title={getTranslationByLangOrEng(
        //     interfaceLanguage,
        //     'companies_reviews_table_header_contact_type',
        //   )}
        //
        //   setFilterParams={setFilterParams}
        //   setReQuery={() => setReQuery(!reQuery)}
        //   setApply={applyFilters}
        // />
        ,
        accessor: 'connectionChannel',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => {
          const contactsData = reviewContactsRef.current.filter((arr: IContact[]) => arr?.some((obj: IContact) => obj.clientID === row.original.id));
          return <ConnectionChannelExtraField answers={row.original.answers} contacts={contactsData.flat()} />;
        }
      }] : []), ...(tableColumnsSettingsRef.current?.comment ? [{
        Header: `${getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_comment_column_title')}`,
        accessor: 'comment',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <CommentExtraField comment={row.original.comment} />
      }] : []), ...(tableColumnsSettingsRef.current?.name ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_user_name_column_title'),
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <UserNameExtraField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.phone ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_phone_column_title'),
        accessor: 'phone',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <PhoneNumberExtraField answers={row.original.answers} />
      }] : []), ...(tableColumnsSettingsRef.current?.email ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_email_column_title'),
        accessor: 'email',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <EmailExtraField answers={row.original.answers} />
      }] : []), ...(languagesState?.length && tableColumnsSettingsRef.current?.language ? [{
        Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_language'),
        accessor: 'language',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <LanguagesExtraField lang={row.original.language} />
      }] : []), ...(tableColumnsSettingsRef.current?.categories ? [{
        Header: <FilterSelectInput title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_categories_column_title')} columnName="categories" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'categories',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <CategoryField index={row.id}
        // eslint-disable-next-line eqeqeq
        isOpened={String(row.id) == String(selectedCategoryRow)} setSelectedRow={setSelectedCategoryRow} categories={row.original.categories} />
      }] : []), ...(tableColumnsSettingsRef.current?.tags ? [{
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput filtersOpenerStyles={{
          minWidth: 250
        }} columnName="tag" title="Теги" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'tags',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <TagsField index={row.id}
        // eslint-disable-next-line eqeqeq
        isOpened={String(row.id) == String(selectedTagRow)} setSelectedRow={setSelectedTagRow} tags={row.original.tags ?? []} />
      }] : []), ...(tableColumnsSettingsRef.current?.attachments ? [{
        Header: <FilterSelectInput columnName="attachmentTypes" title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_attachments_column_title')} filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'attachments',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <AttachmentField answers={row.original.answers.filter((el: any) => el.type === 'file_upload')} />
      }] : [])];
      setHeaders(data);
    }
  }, [
  // reQuery,
  formsRef.current, sourcesRef.current, reviewsRef.current, interfaceLanguage, tableColumnsSettingsRef.current, selectedCategoryRow, selectedTagRow]);
  useEffect(() => {
    filterKeys.forEach(key => {
      if (search.getAll(key).length) filterParamsRef.current[key] = search.getAll(key);
    });
    // setReQuery((e) => !e);
  }, []);
  useEffect(() => {
    if (!loadingReviews) {
      const newFilterParams = mapFiltersBy(filterParamsRef.current as {
        [key: string]: TOption[];
      });
      if (newFilterParams.companyID) {
        setLoadingReviews(true);
        reviewsRef.current = undefined;
        const questionParams: {
          [key: number]: any;
        } = {};
        if (filterQuestions.current.length) {
          filterQuestions.current.forEach(e => {
            const selectedAnswers = e.answers?.filter(ans => ans.value).map(e => e.name);
            if (selectedAnswers?.length) questionParams[e.id] = selectedAnswers;
          });
        }
        reviewApi.postDataToGetFilteredReviews({
          ...newFilterParams,
          // ...filterParamsRef.current,
          statuses: (newFilterParams.statuses as string[])?.map(statusID => +statusID),
          questionAnswers: {
            858866: filterParamsRef.current.csi as string | number | string[] | number[] ?? [],
            ...questionParams
          },
          search: {
            [selectedType.name]: debouncedSearchField
          } as {
            clientID: string;
            phone: string;
            comment: string;
            [key: string]: any;
          }
        }, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize).then(formsResult => {
          workWithResponse(formsResult);
        });
      }
    }
  }, [pageNumber, reQuery, pageSize, debouncedSearchField]);
  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      companyApi.getCompanyMetadataByCompanyId(+filterParamsRef.current.companyID).then(res => {
        if (res.statusCode === 200) {
          dispatch(setCompanyMetadata(res.data));
          const tableSettings: {
            [key: string]: boolean;
          } = {};
          const tableColumnSettings = res.data?.reviewTableSettings;
          tableColumnSettings?.forEach(item => {
            tableSettings[mapTableSettingsKeyNames(item.name)] = true;
          });
          tableColumnsSettingsRef.current = tableSettings;
        }
      }).catch(e => {
        console.log('e', e);
      });
    }
  }, [filterParamsRef.current.companyID]);
  const shouldShowTable = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && filterParamsRef.current?.companyID;
  const shouldShowSearch = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && filterParamsRef.current?.companyID;
  const shouldShowStatuses = !!formsRef.current && !!reviewsRef.current && !loadingReviews && clientStatuses;
  const shouldShowTranspButtons = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && !!filterParamsRef.current?.companyID;
  const shouldShowClearFilters = !!formsRef.current && !!reviewsRef.current && !!headers && !loadingReviews && !!(Object.entries(filterParamsRef.current).length > 1);
  return <ReviewsTableStyles tableVersion={width < 1020} mobileVersion={width < 500}>
      <Helmet>
        <title>Відгуки</title>
      </Helmet>
      <SubHeader companyID={+filterParamsRef.current.companyID} title={`${getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_title')} (${reviewsQuantityRef.current})`} strechChild>
        <div className="reviews-subheader">
          {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={applyFilters} setReQuery={() => setReQuery(!reQuery)} />}
        </div>
      </SubHeader>

      {shouldShowStatuses && <Expandable isOpen={statusesCounts}>
          <StatusesCounter clientStatuses={clientStatuses} />
        </Expandable>}
      <div className="reviews-utils">
        {shouldShowSearch && <SearchContainer>
            <input placeholder={selectedType.placeholder} type={selectedType.name === DropDownKeys.comment ? 'text' : 'number'} value={searchField} onChange={({
          target: {
            value
          }
        }) => {
          setSearchField(value);
        }} />
            <DropDownContainer>
              <StyledSelect>
                <CustomSelect containerStyle={{
              borderWidth: 0,
              backgroundColor: '#E7EEF3',
              marginTop: 0,
              padding: '2px 4px 2px 12px',
              borderRadius: 999,
              height: 28
            }} options={options} valueField={selectedType.name} selected={selectedType} labelField="label" name="name" onChange={e => {
              setSelectedType(e);
              setSearchField('');
            }} placeholder="Enter type" />
              </StyledSelect>
            </DropDownContainer>
          </SearchContainer>}
        {shouldShowTranspButtons && <div className="reviews-btns">
            <button type="button" className="reviews-btn" onClick={() => setStatusesCounts(e => !e)} aria-label="Статистика по статусам відгуків" title="Статистика по статусам відгуків">
              <ReviewStatuses />
            </button>
            <button type="button" className="reviews-btn" onClick={downloadExcel} aria-label="Вивантажити відгуки" title="Вивантажити відгуки">
              <SaveReviews />
            </button>
            <TransparentButton filled className="reviews-extrabtn" handleClick={() => {
          setAdditionalFilters(e => !e);
        }} text={additionalFilters ? 'Сховати фільтри' : 'Додаткові фільтри'} />
            {shouldShowClearFilters && <button type="button" className="reviews-btn" onClick={refreshFilter} aria-label="Очистити фільтри" title="Очистити фільтри">
                <ClearFilters />
              </button>}
          </div>}
      </div>
      {shouldShowTable && <Expandable isOpen={additionalFilters}>
          {/* <FilterSelectInput
            columnName="cnap"
            title="ЦНАП/СНАП"
            filterParams={filterParamsRef.current}
            setFilterParams={setFilterParams}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={applyFilters}
           /> */}
          <FilterSelectInput extraStyles={{
        width: 170
      }} columnName="types" title="тип ЦНАП" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />
          <FilterSelectInput columnName="years" title="Рік" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />
          <FilterSelectInput columnName="answers" title="Відповіді на запитання"
      // @ts-ignore
      filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} filterQuestions={filterQuestions.current} resetFilterQuestions={resetFilterQuestions} />
          {/* <FilterSelectInput
            columnName="services"
            title="Послуги якими скористались"
            filterParams={filterParamsRef.current}
            setFilterParams={setFilterParams}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={applyFilters}
           /> */}
        </Expandable>}
      {loadingReviews && <Loader />}
      {!loadingReviews && !filterParamsRef.current.companyID && <div className="chooseCompanyTitleContainer">
          <div className="chooseCompanyTitle">
            {translations[interfaceLanguage].reviews_page_choose_company_title}
          </div>
        </div>}
      {shouldShowTable && <Table pagination fixedHeader columns={headers} data={reviewsRef.current || []}
    // hideFieldsSplitters
    extraFieldsSettings={extraFieldsSettings} onClickToRow={onClickToRow} rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_review_title')} pageSize={pageSize} setPageSize={setPageSize} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={reviewsQuantityRef.current} pageNumberHandler={page => {
      if (page) {
        const params = createSearchParams(mapFiltersBy(filterParamsRef.current as {
          [key: string]: TOption[];
        }) as Record<string, string | string[]>);
        navigate(`/reviews/page/${page}?${params}`);
      }
    }} />}
    </ReviewsTableStyles>;
};