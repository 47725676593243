import styled from 'styled-components';
export const CompanyUsersStyles = styled.div`
  padding: 15px;
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 151px);

  .searchWrapper {
    display: flex;
    align-items: center;
    background-color: #e7eef3;
    border: 4px solid white;
    border-radius: 5px;
    margin-bottom: 10px;

    input {
      background-color: transparent;
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    button {
      min-width: 130px;
    }

    input::placeholder {
      font-size: var(--fs-2);
      color: #6c6c6c;
    }
  }

  .tableUsers {
    width: 100%;
    height: 100%;
  }
`;