import styled from 'styled-components';

export const CompanySelectStyles = styled.div`
  width: 100%;

  .labelContainer {
    margin-bottom: 8px;
    display: flex;

    label {
      font-weight: 500;
      font-size: var(--fs-2);
      line-height: 120%;
      color: #000000;
    }

    .required {
      color: #ff0101;
    }
  }

  .selectField {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;

    .selectedValue {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowDownActive {
        transform: rotate(180deg);
      }
    }

    .valueContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 20px;
      align-items: center;
      font-size: var(--fs-3);
      color: #000;

      .value {
        display: flex;
        alignitems: center;
      }

      &.withExtraItem {
        grid-template-columns: auto 1fr auto;
      }
    }

    .placeholder {
      font-size: var(--fs-3);
      color: #6c6c6c;
    }

    label {
      font-size: var(--fs-3);
      line-height: 120% !important;
      padding: 0;
      margin: 0;
    }

    .options {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      width: 100%;
      overflow-y: auto;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      max-height: 200px;

      & > .option:last-child .row {
        border-bottom: none;
      }

      .option {
        .rowSel {
          width: 100%;
          align-items: center;
          border-bottom: 1px solid #c4c4c4;
          box-sizing: border-box;
          height: 42px;
        }

        input {
          display: none;
          height: 32px;
        }

        button {
          width: 100%;
          height: 100%;
          font-size: var(--fs-3);

          .labelContent {
            display: flex;
            gap: 20px;
            align-items: center;
            text-align: left;
            padding-left: 16px;
            width: 100%;
            height: 100%;
            color: #6e6e6e;
            box-sizing: border-box;

            .default {
              cursor: pointer;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 22px;
              height: 22px;
              background-color: #ffffff;
              border: 1px solid #e5e5e5;
              box-sizing: border-box;
            }
          }
        }
      }

      .searchWrapper {
        height: 42px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c4c4c4;
        box-sizing: border-box;
        padding: 0 16px;
        background-color: #fcfcfc;

        input {
          flex-grow: 1;
          margin-left: 12px;
          padding: 0;
          border: none;
          outline: none;
          font-size: var(--fs-3);
          color: #000;
          background-color: #fcfcfc;

          &::placeholder {
            font-size: var(--fs-3);
            color: #6e6e6e;
          }
        }
      }
    }
  }
`;
