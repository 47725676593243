import styled from 'styled-components';

export const CompaniesStyles = styled.div`
  width: 100%;

  .customButtonsWrapper {
    display: flex;

    button {
      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .customField {
    font-weight: 500;
    font-size: var(--fs-1);
    line-height: 28px;
    color: #000000;
    text-decoration: none;

    div {
      display: flex;
      justify-content: center;
      width: 171px;
      height: 32px;
      background: #f0f0f0;
      border: 1px solid #000;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 400;
      font-size: var(--fs-3);
      color: #000;
      transition: 0.3s all;

      :hover {
        background: #000;
        color: #f0f0f0;
      }
    }
  }

  .customNameWrapper {
    display: flex;
    align-items: center;
  }

  .company-btns {
    display: flex;
    align-items: center;
    gap: 10px;

    .company-btn {
      font-size: var(--fs-3);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .editButtonsContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    .editButtons {
      width: 140px;
      display: grid;
      grid-template-columns: 1fr 1fr 20px 1fr;
      align-items: center;

      .openButton {
        svg {
          height: 20px;
        }
      }

      button,
      a {
        display: flex;
        justify-content: center;
      }

      .splitter {
        height: 24px;
        display: flex;
        justify-content: center;

        > div {
          width: 1px;
          height: 100%;
          background-color: #eee;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
    }
  }

  .editButtons {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .searchWrapper {
    background-color: white;
    padding: 4px;
    margin-bottom: 15px;
    border-radius: 5px;
    .search {
      display: flex;
      align-items: center;
      padding: 0 19px;
      border-radius: 5px;
      background-color: #e7eef3;
      input {
        background-color: transparent;
        width: 100%;
        height: 48px;
        margin-left: 11px;
        border: none;
        outline: none;
      }

      input::placeholder {
        font-size: var(--fs-2);
        color: #6c6c6c;
      }
    }
  }

  .modalBody {
    .modalButtonsWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: var(--fs-3);
        color: #000000;
      }

      div {
        display: flex;
        justify-content: space-between;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: var(--fs-3);
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;

        :nth-child(1) {
          background: #ffffff;
          border: 1px solid #c4c4c4;
          color: #000000;

          :hover {
            color: #fff;
            background: #c4c4c4;
          }
        }

        :nth-child(2) {
          border: 1px solid #ff0101;
          background: #ff0101;
          color: #ffffff;

          :hover {
            background: #ffffff;
            color: #ff0101;
          }
        }
      }
    }

    .modalError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
