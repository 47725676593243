import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Layout } from './components';
import { useAppDispatch, useAppSelector, setInterfaceLanguage, setUser } from './state';
import AddCompany from './pages/companies/addCompany/AddCompany';
import AddNode from './pages/companies/addNode/AddNode';
import EditCompany from './pages/companies/editCompany/EditCompany';
import Forms from './pages/companies/forms/Forms';
import Documents from './pages/companies/documents/Documents';
import AddDocument from './pages/companies/addDocument/AddDocument';
import { AddIssue, AddLanguage, AddPermission, AddUser, Analytic, ErrorPage, Impex, Issues, Languages, Permissions, Profile, Recovery, Login, ResetPassword, Review, ReviewsTable, AddRole, Roles, Users, Companies, Massmailer, Internal, Constructor } from './pages';
import { getInterfaceLanguage } from './utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserDataRefill } from './pages/userDataComplete';
import { SelectRole } from './pages/selectRole';
import { RegistrationStatus } from './constants/registrationStatuses';
import { AccessRequests } from './pages/accessRequests';
import { AccessRequestThankYouPage } from './pages/accessRequestThankYouPage';
import { RegistrationStatuses, useRegistrationStatus } from './hooks/useRegistrationStatus';
import { AdminLogin } from './pages/adminLogin';
import { devMode } from './constants';
import styled from 'styled-components';
import { authApi } from './api';
export const DevLabel = styled.div`
  width: 150px;
  height: 40px;
  border-radius: 20px;
  background-color: yellow;
  right: -5px;
  bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100000;
  border: 2px solid red;
  overflow: hidden;
  transform: rotate(-45deg);
`;
const UserTracker = () => {
  const location = useLocation();
  const {
    data
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data?.id) {
      authApi.getUserMe().then(res => {
        dispatch(setUser(res.data));
      });
    }
  }, [location]);
  return null;
};
function App() {
  const helmetContext = {};
  const dispatch = useAppDispatch();
  const {
    data,
    status
  } = useAppSelector(state => state.user);
  const loggedIn = data?.status === RegistrationStatus.assigned;
  useEffect(() => {
    dispatch(setInterfaceLanguage(getInterfaceLanguage() || 'UA'));
  }, []);
  return <HelmetProvider context={helmetContext}>
      <ToastContainer />
      <BrowserRouter>
        <UserTracker />
        <Routes>
          <Route path="/error/:id" element={<ErrorPage />} />
          <Route element={<Layout />}>
            {loggedIn && <>
                <Route path="/" element={<Navigate to="/reviews" />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/analytics" element={<Analytic />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/create" element={<AddCompany />} />
                <Route path="/companies/:id/nodes" element={<Internal />} />
                <Route path="/companies/:companyId/nodes/add" element={<AddNode />} />
                <Route path="/companies/:id/edit" element={<EditCompany />} />
                <Route path="/companies/:companyId/nodes/:nodeId/forms" element={<Forms />} />
                <Route path="/companies/:companyId/forms/create" element={<Constructor />} />
                <Route path="/companies/:companyId/forms/:formId" element={<Constructor />} />
                <Route path="/companies/:companyId/documents" element={<Documents />} />
                <Route path="/companies/:companyId/documents/create" element={<AddDocument />} />
                <Route path="/companies/:companyId/documents/:documentId/edit" element={<AddDocument />} />

                {/* Reviews */}
                <Route path="/reviews" element={<Navigate to="/reviews/page/1" />} />
                <Route path="/reviews/page/:pageNumber" element={<ReviewsTable />} />
                <Route path="/reviews/:companyID" element={<ReviewsTable />} />
                <Route path="/reviews/:companyID/:node_id" element={<ReviewsTable />} />
                <Route path="/reviews/company/:companyId/review/:id" element={<Review />} />

                {/* Issues */}
                <Route path="/tickets" element={<Issues />} />
                <Route path="/tickets/page/:pageNumber" element={<Issues />} />
                {/* <Route path="/tickets/create" element={<AddIssue />} /> */}
                <Route path="/tickets/:id" element={<AddIssue />} />

                {/* Users */}
                <Route path="/users" element={<Navigate to="/users/page/1" />} />
                <Route path="/users/page/:pageNumber" element={<Users />} />
                <Route path="/users/create" element={<AddUser />} />

                <Route path="/users/roles" element={<Roles />} />
                <Route path="/access_requests" element={<Navigate to="/access_requests/manage/page/1" />} />
                <Route path="/access_requests/manage/page/:pageNumber" element={<AccessRequests />} />
                <Route path="/users/roles/create" element={<AddRole />} />
                <Route path="/users/roles/:id" element={<AddRole />} />
                <Route path="/users/permissions" element={<Permissions />} />
                <Route path="/users/permissions/page/:pageNumber" element={<Permissions />} />
                <Route path="/users/permissions/create" element={<AddPermission />} />
                <Route path="/users/permissions/:id" element={<AddPermission />} />

                {/* Languages */}
                <Route path="/languages" element={<Languages />} />
                <Route path="/languages/page/:pageNumber" element={<Languages />} />
                <Route path="/languages/create" element={<AddLanguage />} />

                {/* Mass mailing v2 */}
                <Route path="/massmailer" element={<Massmailer />} />

                {/* Import/Export */}
                <Route path="/impex" element={<Impex />} />
              </>}
            {!loggedIn && <>
                {data?.id && data?.status === RegistrationStatus.pre_registered && <Route path="/" element={<Navigate to={`/auth/complete?user_id=${data?.id}`} />} />}
                {data?.status === RegistrationStatus.registered && <Route path="/" element={<Navigate to="/auth/thank_you_page" />} />}
                <Route path="/" element={<Navigate to="/auth/login" />} />
                <Route path="/auth/login" element={<Login />} />
                <Route path="/auth/admin" element={<AdminLogin />} />
                <Route path="/auth/complete" element={<UserDataRefill />} />
                <Route path="/auth/select_role" element={<SelectRole />} />
                <Route path="/auth/thank_you_page" element={<AccessRequestThankYouPage />} />
                <Route path="/auth/recovery" element={<Recovery />} />
                <Route path="/auth/password-reset" element={<ResetPassword />} />
              </>}
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {devMode && <DevLabel>
            <p>dev build</p>
          </DevLabel>}
      </BrowserRouter>
    </HelmetProvider>;
}
export default App;