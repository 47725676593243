import styled from 'styled-components';

export const ResponsibleDropdownStyles = styled.div`
  z-index: 99;
  position: absolute;
  left: 50%;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px;

  .userName {
    padding: 6px 10px;
    font-size: var(--fs-2);
    white-space: nowrap;
    color: rgb(110, 110, 110);
    &:not(:first-child) {
      border-top: 1px solid rgb(196, 196, 196);
    }
  }
`;
