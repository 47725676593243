import styled from 'styled-components';

export const CompanyCategoriesStyles = styled.div`
    max-width: 544px;
    width: 100%;
    border: 1px solid rgb(240, 240, 240);
    display: flex;
    flex-direction: column;

    .headWrapper {
      padding: 9px 11px 9px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f0f5f5;

      span {
        font-weight: 500;
        font-size: var(--fs-2);
        color: #000000;
      }
    }

    .itemsWrapper {
      padding: 5px 16px;
      height: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid #f0f0f0;

      svg {
        flex-shrink: 0;
      }
    }
`;
