import styled from 'styled-components';

export const LocationsWithoutReviewsStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .counts {
    display: flex;
    align-items: center;
    gap: 25px;

    &__item {
      font-size: var(--fs-2);
    }
  }

  .rating {
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: var(--fs-3);
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1440px) {
        padding: 6px 10px;
        font-size: var(--fs-5);
      }
    }

    &-item {
      font-size: var(--fs-3);

      @media screen and (max-width: 1440px) {
        font-size: var(--fs-5);
      }
    }
  }
`;
