export const extraFieldsSettings = {
  id: {
    minWidth: 0.1,
  },
  nodesCount: {
    minWidth: 0.1,
  },
  responsibleCount: {
    minWidth: 0.1,
  },
};
