import styled from 'styled-components';

export const QRStylesColorPickerStyles = styled.div`
  .qrStylesColorPickerOpenerButton {
    display: flex;
    gap: 16px;
    align-items: center;
    
    font-weight: 500;
    font-size: var(--fs-2);
    line-height: 120%;
    color: #000000;
    
    .colorPreview {
      width: 40px;
      height: 40px;
      border-radius: 4px;
    }
  }
`;
