import styled from 'styled-components';

export const resetFilterButtonStyles = {
  width: '100%',
  // maxWidth: '270px',
  marginTop: '14px',
  marginBottom: '14px',
  backgroundColor: '#ccc',
  color: '#ffffff',
  border: 'none',
};

export const FilterSelectInputStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  /* background-color: red; */

  .sortContainer {
    /* background-color: red; */
    padding: 5px 10px;
    cursor: pointer;
  }

  .filterRow {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 2px solid rgba(219, 226, 231, 1);
    padding-left: 10px;
    /* padding-right: 10px; */
    svg {
      margin-left: 0px;
    }
  }
  .filterRow:hover {
    background-color: #dbe2e7;
  }

  .scrollable {
    form {
      padding-bottom: 15px;
    }
  }
  .entityTitle {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 8px;
    }

    svg {
      transition: all 0.3s ease-in-out;
      width: 16px;
      height: 16px;
    }
  }

  .filterTitle {
    font-weight: 400;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
  }

  .filtersOpener {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    min-width: 150px;
    align-items: center;
    padding: 4px 10px;

    @media screen and (max-width: 1440px) {
      padding: 2px 8px;
    }

    &.active {
      svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }

  .filterContainer {
    /* background-color: red; */
  }
`;

export const DropdownFilterStyles = styled.div<{ width?: number }>`
  align-self: center;
  min-width: ${({ width }) => width ?? 200}px;

  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: absolute;
  top: 100%;
  /* left: 0px;
  right: 0px; */
  z-index: 200;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  max-height: 350px;
  overflow: scroll;
  padding-bottom: 15px;

  .selectContainer {
  }
`;
