import styled, { css } from 'styled-components';

export const SubmitButtonStyles = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 33px;
    padding: 16px 40px;
    background: #000;
    border: none;
    transition: all 0.5s;
    font-weight: 600;
    font-size: var(--fs-2);
    color: #ffffff;
    font-weight: 600;
  }
`;
