import styled from 'styled-components';

export const ImportStyles = styled.div`
  .importPageWrapper {
    display: flex;
    padding: 17px 38px 17px 17px;
    .formSection {
      margin-right: 50px;
      .fileForImportContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 400px;
        margin-bottom: 10px;

        .helperWrapper {
          display: flex;
          align-items: center;

          .deleteFileButton {
            padding-top: 4px;
            margin-right: 20px;
          }
        }
      }

      .fileFormatTextConatiner {
        margin-bottom: 40px;
      }

      .fileError {
        height: 24px;
        font-size: var(--fs-5);
        line-height: 120%;
        color: #ff3333;
      }

      .submitError,
      .interruptError {
        margin-top: 12px;
        color: #ff3333;
        font-size: var(--fs-3);
        text-align: center;
      }

      .interruptImportButtonContainer {
        margin-top: 20px;
        .interruptImportButton {
          width: 100%;
          background-color: rgb(255, 1, 1);
          color: #fff;
          min-height: 40px;
          font-weight: 400;
          font-size: var(--fs-3);
          border-radius: 4px;
        }
      }
    }
    .progressBarContainer {
      .progressBarLabel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;

        .progressBarValues {
          margin-left: 10px;
        }
      }
    }

    .currentImportStatusError {
      margin-top: 12px;
      color: #ff3333;
      font-size: var(--fs-3);
      text-align: center;
    }
  }

  .importErrorContainer {
    margin-top: 20px;
    .importErrorTitle {
      color: #ff0000;
    }

    .importErrorTextContainer {
      margin-top: 6px;
      min-height: 50px;
      width: 100%;
      max-width: 360px;
      background-color: rgba(255, 0, 0, 0.2);
      padding: 10px;
      border-radius: 6px;
      border: 1px solid #ff0000;
    }
  }

  progress {
    border-radius: 7px;
    width: 200px;
    height: 14px;
  }
  progress::-webkit-progress-bar {
    border-radius: 7px;
    background-color: #d3d3d3;
  }
  progress::-webkit-progress-value {
    background-color: #000;
    border-radius: 7px;
  }
`;
