/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import {
  ApiCompanyField,
  ApiCreatedField,
  ApiResponse,
  ApiUpdatedField,
  FieldType,
  TAnswer,
} from '../entities';
import IFieldsApi from './IFieldsApi';

class FieldsApi implements IFieldsApi {
  public async getCompanyFields(
    id: number,
    offset?: number,
    limit?: number,
    question?: string,
  ): Promise<ApiResponse<ApiCompanyField[]>> {
    const res = await baseApi.getData(
      `/companies/${id}/fields?offset=${offset || 0}&limit=${limit || 10}${
        question ? `&question=${question}` : ''
      }`,
    );

    return Promise.resolve({
      data: res.data.data,
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async createCompanyFields(
    id: number,
    data: ApiCreatedField,
  ): Promise<ApiResponse<ApiCompanyField>> {
    return baseApi.postData(`/companies/${id}/fields`, data);
  }

  public async updateFormFieldAnswer(
    id: number,
    data: TAnswer[],
  ): Promise<ApiResponse<TAnswer>> {
    return baseApi.putData(`/form_fields/${id}/answers`, { answers: data });
  }

  public async updateFormField<K extends FieldType>(
    id: number,
    data: ApiUpdatedField<K>,
  ): Promise<ApiResponse<any>> {
    return baseApi.putData(`/form_fields/${id}`, data);
  }

  public async getTargetField(
    id: number,
  ): Promise<ApiResponse<ApiCompanyField>> {
    const res = await baseApi.getData(`/form_fields/${id}`);

    return Promise.resolve({
      data: {
        ...res.data,
        options: res.data.options ? res.data.options : [],
        styles: res.data.styles ? res.data.styles : [],
      },
      statusCode: res.statusCode,
    });
  }

  public async getTargetFieldAnswers(
    id: number,
  ): Promise<ApiResponse<string[]>> {
    const res = await baseApi.getData(`/form_fields/${id}/answers`);
    return Promise.resolve({
      data: res.data,
      statusCode: res.statusCode,
    });
  }

  public async deleteFormField(id: number): Promise<ApiResponse<any>> {
    return baseApi.deleteData(`/form_fields/${id}`);
  }
}

export const fieldsApi = new FieldsApi();
