import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { IssuesStyles } from './styles';
import React, { useEffect, useRef, useState } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getNodesDictionary, parseSearchString } from '../../utils';
import { issuesApi, companyApi, formApi, ApiIssue } from '../../api';
import { INode, CompaniesMainInfo } from '../../entities';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { CompanyFilter, CustomTabsMenu, deduplicateArray, FilterSelectInput, Loader, SubHeader, Table } from '../../components';
import { TabKeys, tabs } from './utils';
import { useAppSelector } from '../../state';
import { availablePageSizes } from '../../constants';
const Issues_1 = _compiledBlock(_props => _props.v0, {
  name: "Issues_1",
  portals: ["v0"]
});
export const Issues = () => {
  const navigate = useNavigate();
  const {
    pageNumber
  } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: user
  } = useAppSelector(state => state.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [issues, setIssues] = useState<ApiIssue[]>([]);
  const [reQuery, setReQuery] = useState<boolean>();
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageSize, setPageSize] = useState(availablePageSizes[1]);
  const [selectedTab, setSelectedTab] = useState<string>(TabKeys.all);
  const issuesCount = useRef<number>(0);
  const nodesRef = useRef<{
    [key: string]: Partial<INode>;
  }>({});
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;
      return acc;
    }, {});
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: compID
      };
      const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
      navigate(`/tickets/page/1?${path}`);
      setReQuery(!reQuery);
    }
  };
  const getCompanyNodes = async (id: number) => {
    const res = await formApi.getCompanyNodes(id);
    if (res.statusCode >= 200 && res.statusCode < 300) {
      const newNodesDictionary: {
        [key: number]: Partial<INode>;
      } = {};

      // @ts-ignore
      getNodesDictionary(newNodesDictionary, res.data);
      const nodesDictionary = nodesRef.current ? {
        ...nodesRef.current,
        ...newNodesDictionary
      } : newNodesDictionary;
      if (Object.keys(nodesDictionary).length > 0) {
        // @ts-ignore
        nodesRef.current = nodesDictionary;
      }
    }
  };
  const onClickToRow = async (data: any) => {
    if (!data.status) {
      await issuesApi.updateTicket(data.id, {
        ...data,
        status: 1
      }).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setReQuery(!reQuery);
        }
      });
    }
    navigate(`/tickets/${data.id}`);
  };
  const applyFilters = (params: any) => {
    filterParamsRef.current = params;
    const path = createSearchParams(params as Record<string, string | string[]>);
    navigate(`/tickets/page/1?${path}`);
  };
  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
  };
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      setLoading(true);
      const filterParams = searchParams.size ? parseSearchString(searchParams) : {};
      filterParamsRef.current = {
        ...filterParams,
        ...filterParamsRef.current
      };
      if (selectedTab === TabKeys.forUser && user) {
        filterParams.responsibleIDs = [user.id];
      }
      if (selectedTab === TabKeys.fromUser && user) {
        filterParams.userIDs = [user.id];
      }
      if (filterParams.companyID) {
        issuesApi.getFilteredTickets(filterParams, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize).then(res => {
          setIssues(res.data);
          issuesCount.current = res.count ? res.count : 0;
          setLoading(false);
        });
      } else {
        setIssues([]);
        setLoading(false);
      }
    }
  }, [pageNumber, reQuery, selectedTab]);
  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      getCompanyNodes(+filterParamsRef.current.companyID);
    }
  }, [filterParamsRef.current.companyID, reQuery]);
  useEffect(() => {
    const data = [{
      Header: '№',
      accessor: 'id',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>{data.row.original.id}</div>
    }, {
      Header: 'Назва',
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>{data.row.original?.name}</div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput filterName="nodeIDs" title="Фронт-офіс" columnName="nodes" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
      accessor: 'node',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>{data.row.original.node?.name}</div>
    }, {
      Header: <FilterSelectInput columnName="createdAt" title="Дата створення" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
      accessor: 'date',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>
            {format(new Date(data.row.original.createdAt), 'dd.MM.yyyy hh:mm a')}
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <FilterSelectInput filtersOpenerStyles={{
        minWidth: 250
      }} filterName="statusIDs" title="Статус" columnName="statusIDs" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>
            {data.row.original.status?.name}
          </div>
    }, {
      Header: 'Пріоритет',
      accessor: 'priority',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>{data.row.original.priority}</div>
    }, {
      Header: 'Ким делеговане',
      accessor: 'user',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>{data.row.original.user?.name}</div>
    }, {
      Header: 'Виконавець',
      accessor: 'responsible',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div style={{
        color: '#000'
      }}>
            {data.row.original.responsible?.name}
          </div>
    }];
    setTableHeaders(data);
  }, [filterParamsRef.current]);
  return /*@million jsx-skip*/<Issues_1 v0={<IssuesStyles>
      <Helmet>
        <title>{`Завдання (${issuesCount.current})`}</title>
      </Helmet>
      <SubHeader strechChild title={`Завдання (${issuesCount.current})`}>
        <div className="issues-subheader">
          {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={applyFilters} setReQuery={() => setReQuery(!reQuery)} />}
        </div>
      </SubHeader>
      {filterParamsRef.current.companyID && <div className="issues-tabs">
          <CustomTabsMenu tabs={tabs} selectedTab={selectedTab} setSelectedTab={(key: any) => {
        setSelectedTab(key as TabKeys);
      }} withContainer />
        </div>}
      <div className="issues-wrapper">
        {loading && <Loader />}
        {tableHeaders && !loading && filterParamsRef.current.companyID && <Table pagination fixedHeader columns={tableHeaders} data={issues} onClickToRow={onClickToRow} rowTitle="Відкрити завдання" rowsQuantity={issuesCount.current} currentPage={pageNumber ? +pageNumber : 1} pageSize={pageSize} pageNumberHandler={page => {
        if (page) {
          const params = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
          navigate(`/tickets/page/${page}?${params}`);
        }
      }} />}
      </div>
    </IssuesStyles>} />;
};