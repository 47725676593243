import styled from 'styled-components';

export const FormsPagesStyles = styled.div`
   display: flex;
   flex-direction: column;
   max-width: 250px;
   background: rgb(231, 238, 243);
   border-right: 1px solid rgb(229, 229, 229);
   width: 100%;
   flex-shrink: 0;

   .utils {
      padding: 12px 16px;
      width: 100%;
      border-bottom: 1px solid rgb(229, 229, 229);
      background: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      height: 50px;

      .checkboxValue {
         font-size: var(--fs-5);
      }
   }

   .pages {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;
      height: 100%;
      overflow: auto;
      position: relative;

      &-item {
         height: 50px;
         flex-shrink: 0;
         display: flex;
         align-items: center;
         padding: 8px 12px;
         background: #fff;
         cursor: pointer;
         position: relative;

         &-selected {
            box-shadow: inset 0 0 0 3px rgb(229, 229, 229);
         }

         &-del {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 16px;
         }
      }

      &-info {
         display: flex;
         flex-direction: column;
         gap: 5px;
         position: absolute;
         top: 50%;
         transform: translate(-50%, -50%);
         left: 50%;

         p {
            font-size: var(--fs-7);
            color: rgb(120 120 120);
         }
      }

      &-add {
         margin-top: 5px;
         cursor: pointer;
         text-align: center;
         padding: 8px 12px;
         border: 2px dashed rgb(128, 128, 128);
         font-size: var(--fs-3);
      }
   }
`;
