import styled from 'styled-components';

export const CampaignStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .campaign-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 10px 20px;
    box-shadow: 0 3px 3px 0 rgba(50, 50, 50, 0.1);
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    position: relative;

    .campaign-title {
      display: flex;
      gap: 20px;

      h3 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: var(--fs-3);
      }

      p {
        font-size: var(--fs-5);
        color: rgb(147, 145, 145);
      }
    }

    .campaign-date {
      font-size: var(--fs-5);
      font-style: italic;
    }

    .arrow {
      position: absolute;
      z-index: 2;
      right: 0;
      transition: all 0.3s ease;
      transform: rotate(180deg);

      &.active {
        transform: rotate(0deg);
      }
    }
  }

  .campaign-info-content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    display: none;
    flex-direction: column;
  }

  .campaign-info-content.show {
    display: flex;
  }

  .btn-container {
    display: flex;
    width: 100%;
    margin-top: 10px;

    .del-btn {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .icon-container {
    width: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete_modal {
    .subtitle {
      margin-bottom: 20px;
      line-height: 150%;
    }
    .row {
      justify-content: space-between;
      display: flex;
    }
  }
`;

export const StatusesCounterStyles = styled.div`
  .wrapper {
    overflow: auto;
    display: flex;
    width: calc(100% - 4px);
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(225, 225, 225);
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    .status {
      padding: 8px 16px 12px 16px;
      display: flex;
      flex-direction: column;
      width: 20%;
      border-right: 1px solid rgb(225, 225, 225);

      .statusHead {
        display: flex;
        align-items: center;
      }

      .statusCount {
        margin-right: 12px;
        font-weight: 700;
        font-size: var(--fs-1);
      }

      svg {
        flex-shrink: 0;
      }

      .statusContent {
        color: #a1a1a1;

        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      .statusName {
        font-size: var(--fs-3);
      }
    }
    .status:last-child {
      border-right: none;
    }
  }
`;
