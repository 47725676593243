import styled from 'styled-components';

export const CompanyDesignFormStyles = styled.div`
  padding: 26px 32px 26px 16px;

  .designWrapper {
    max-width: 714px;
    width: 100%;
    border: 1px solid #f0f0f0;
    margin-bottom: 25px;

    .headWrapper {
      padding: 9px 11px 9px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f0f5f5;

      span {
        font-weight: 500;
        font-size: var(--fs-2);
        color: #000000;
      }

      .buttonsWrapper {
        width: 274px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .addButtonWrapper {
          width: 232px;

          button {
            width: 232px;
            height: 32px;
            color: #ffffff;
            font-weight: 500;
            font-size: var(--fs-3);
            background-color: #000;
            border: 1px solid #000;
            border-radius: 4px;
            transition: 0.3s;

            span {
              color: #fff;
            }

            :hover {
              color: #000;
              background-color: #ffffff;

              span {
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  form {
    margin-bottom: 8px;

    .arrayWrapper {
      .logosWrapper,
      .colorsWrapper,
      .fontsWrapper {
        height: 56px;
        padding: 0 16px;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 281px;
        grid-gap: 24px;
        border-bottom: 1px solid #f0f0f0;

        &.edit {
          height: 88px;
          align-items: flex-end;
        }

        &:last-child {
          border-bottom: none;
        }

        .inputWrapper {
          font-size: var(--fs-3);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .fileWrapperEditMargin {
          margin-bottom: 24px;
        }

        .fileWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .colorSquare {
            width: 40px;
            height: 40px;
            border: 1px dashed #000;
          }

          .logoImage {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }
    }

    .errorWrapper {
      text-align: right;
      padding-right: 12px;
    }

    .uploadError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #ff3333;
    }
  }
`;
