import styled from 'styled-components';

export const ToggleSwitcherStyles = styled.div`
  label {
    span {
      font-weight: 500;
      font-size: var(--fs-3);
      line-height: 120%;
      color: #000000;
    }
  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .toggleSwitcherWrapper {
    display: flex;
    align-items: center;
  }

  .customizedToggleSwitcher {
    position: relative;
    width: 50px;
    height: 30px;
    margin-right: 12px;
    background-color: #c5d9e9;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 23px;
    flex-shrink: 0;
    
    &.checked {
      background-color: #60c864;
    }
    .circle {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: white;
      transition: all 0.3s;

      &.checked {
        left: 23px;
        background-color: #fff;
        transition: all 0.3s;
      }
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: var(--fs-5);
    line-height: 120%;
    color: #ff3333;
  }
`;
