import styled from 'styled-components';

export const PageContentStyles = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   flex: 1;
   gap: 2px;

   .page {
      height: 100%;
      width: 100%;
      background: rgb(231, 238, 243);
      border-right: 1px solid rgb(229, 229, 229);
      display: flex;
      overflow: auto;
      position: relative;
      flex-direction: column;
      gap: 5px;

      .header {
         justify-content: center;
         height: 50px
      }
   }

   .utils {
      margin: 5px;
      background: #fff;
      padding: 10px;

      &-info {
         display: flex;
         flex-direction: column;
         width: 100%;
         margin: 35px 0 15px;
         gap: 10px;

         p {
            font-size: var(--fs-3);
         }
      }

      &-row {
         display: flex;
         align-items: center;
         gap: 10px;
      }
   }

   .settings {
      margin: 5px;
      padding: 10px;
      height: 100%;
      position: relative;
      /* overflow: auto; */

      &-list {
         display: grid;
         gap: 12px;
      }

      &-item {
         background: rgb(255, 255, 255);
         padding: 12px;
         border-radius: 4px;
         display: flex;
         flex-direction: column;
         gap: 15px;

         &-header {
            display: flex;
            align-items: center;
            gap: 15px;
            position: relative;

            p {
               font-size: var(--fs-5);
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               max-width: 400px;
            }
         }

         &-answers {
            border-top: 1px solid rgb(229, 229, 229);
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            width: 100%;
         }

         &-subtitle {
            font-size: var(--fs-2);
            margin-bottom: 15px;
         }

         &-answer {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid rgb(229, 229, 229);
            gap: 15px;

            &:last-child {
               border-bottom: 1px solid rgb(229, 229, 229);
            }

            p {
               font-size: var(--fs-3);
            }
         }

         &-del {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 16px;
         }
      }
   }
`;
