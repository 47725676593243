import styled from 'styled-components';

export const CompanyInfoFormStyles = styled.div`
  padding: 26px 32px 80px 16px;

  .addLogoWrapper {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 32px;

    .logoContainer {
      height: 80px;
      box-sizing: border-box;
      overflow: hidden;

      &.default {
        display: grid;
        place-items: center;
        background: #f0f0f0;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
      }

      .selectedImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0;

      .description {
        font-size: var(--fs-5);
        line-height: 120%;
        color: #6c6c6c;
      }
    }
  }

  .formContainer {
    max-width: 544px;
    form {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }

  .labelDomain {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .extraFieldContainer {
    position: relative;
    border-top: 1px solid #999;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .fields {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 15px;
    }

    .removeButtonContainer {
      margin-top: 10px;
    }
  }

  .addFieldButtonContainer {
    margin-top: 10px;
    margin-bottom: 36px;
  }

  .uploadError {
    font-size: var(--fs-5);
    line-height: 120%;
    color: #ff3333;
  }

  .multiLanguageWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .multiLanguageTitle {
      font-weight: 500;
      font-size: var(--fs-2);
      line-height: 120%;
      color: #000000;
      margin-bottom: 8px;
    }

    .multiLanguageSelect {
      max-width: 253px;
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      position: relative;

      .headMultiLanguageSelect {
        font-weight: 400;
        font-size: var(--fs-3);
        line-height: 120%;
        color: #6c6c6c;
        padding: 12px 16px;
        border: 1px solid #e5e5e5;
      }

      .checkboxLanguagesList {
        width: 100%;
        position: absolute;
        padding: 10px 4px 6px;
        border: 1px solid #e5e5e5;
        gap: 8px;
        box-sizing: border-box;
        background-color: #ffffff;

        .searchWrapper {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0 16px;
          background-color: #fcfcfc;

          input {
            flex-grow: 1;
            margin-left: 12px;
            padding: 0;
            border: none;
            outline: none;
            font-size: var(--fs-3);
            color: #000;
            background-color: #fcfcfc;

            &::placeholder {
              font-size: var(--fs-3);
              color: #6e6e6e;
            }
          }
        }

        .listItem {
          width: 100%;
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #c4c4c4;
          border-radius: 4px;
          padding: 8px 16px;
          box-sizing: border-box;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          > div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            label {
              display: flex;
              justify-content: center;
              flex-grow: 1;
            }
          }
        }
      }
    }
  }

  form {
    margin-bottom: 8px;
  }
`;
