import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { Helmet } from 'react-helmet-async';
import { tabsStructure } from './utils';
import { CompanyUsers, SubHeader, TransparentButton, CustomTabsMenu, CompanyForms, Structure, CompanyFields } from '../../../components';
import { StructTabKeys } from './TInternal';
import { InternalStyles } from './styles';
import { QRControl } from '../../../components/organisms/qrControl';
import { usePermissionView } from '../../../hooks';
export const Internal = () => {
  const navigate = useNavigate();
  const hasAnyAccess = usePermissionView();
  const {
    id
  } = useParams();
  const location = useLocation();
  const {
    tab
  } = location.state || {};
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedTab, setSelectedTab] = useState(tabsStructure(interfaceLanguage)[0].key);
  const {
    selectedCompany: company
  } = useAppSelector(state => state.company);
  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
  }, []);
  return <InternalStyles>
      <Helmet>
        <title>{`Мережа ${company?.name || ''}`}</title>
      </Helmet>
      <SubHeader title="Внутрішня інформація про суб’єкта моніторингу" pathArray={[...(company ? [{
      name: company.name!
    }] : [])]}>
        {hasAnyAccess('/companies/*/settings').view && <TransparentButton handleClick={() => navigate(`/companies/${id}/edit`)} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_title_edit')} filled />}
        <TransparentButton handleClick={() => navigate('/companies')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
      </SubHeader>
      <CustomTabsMenu tabs={tabsStructure(interfaceLanguage).filter(el => el.access)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === StructTabKeys.structure && <Structure />}
      {selectedTab === StructTabKeys.company_users && <CompanyUsers />}
      {selectedTab === StructTabKeys.company_forms && <CompanyForms />}
      {selectedTab === StructTabKeys.company_fields && <CompanyFields />}
      {selectedTab === StructTabKeys.qr_control && <QRControl />}
    </InternalStyles>;
};