import styled from 'styled-components';

export const ReviewTicketsStyles = styled.div`
  margin: 16px 0;
  width: 100%;

  .ticket {
    width: 100%;
    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      border-bottom: 1px dashed rgb(229, 229, 229);
      padding-bottom: 24px;
    }

    &-empty {
      font-size: var(--fs-2);
      color: rgb(0, 0, 0);
      text-align: center;
    }

    &-item {
      border: 1px solid rgb(229, 229, 229);
      border-radius: 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
    }

    &-del {
      transform: rotate(45deg);
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 5px;

      p {
        font-size: var(--fs-3);
        white-space: nowrap;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #6c6c6c;

        b {
            color: #000;  
        }
      }
    }
    &-form {
      margin-top: 35px;
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
`;
