import styled from 'styled-components';

export const ModalConfirmDeclineStyles = styled.div`
  .modalBody {
    .modalButtonsWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: var(--fs-3);
        color: #000000;
      }

      .modalButtonsInnerWrap {
        display: flex;
        gap: 30px;
        align-items: center;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }

        &.divNoGap {
          gap: 0px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: var(--fs-3);
        border-radius: 4px;
        transition: 0.3s all;
        color: #ffffff;
      }
    }

    .modalError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
