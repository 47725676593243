import styled from 'styled-components';

export const CompanyIssueStatusesAndTypesStyles = styled.div`
  padding: 26px 32px 80px 16px;

  .designWrapper {
    max-width: 544px;
    width: 100%;
    border: 1px solid #f0f0f0;
    margin-bottom: 25px;

    .headWrapper {
      padding: 9px 11px 9px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f0f5f5;

      span {
        font-weight: 500;
        font-size: var(--fs-2);
        color: #000000;
      }

      .buttonsWrapper {
        width: 274px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .addButtonWrapper {
          width: 232px;

          button {
            width: 232px;
            height: 32px;
            color: #ffffff;
            font-weight: 500;
            font-size: var(--fs-3);
            background-color: #000;
            border: 1px solid #000;
            border-radius: 4px;
            transition: 0.3s;

            span {
              color: #fff;
            }

            :hover {
              color: #000;
              background-color: #ffffff;

              span {
                color: #000;
              }
            }
          }
        }
        .addButton {
        }
      }
    }
  }

  form {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .arrayWrapper {
      .itemsWrapper {
        height: 56px;
        padding: 5px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;

        &.edit {
          height: 56px;
        }

        &:last-child {
          border-bottom: none;
        }

        .inputWrapper {
          font-size: var(--fs-3);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .fileWrapper {
          height: 100%;
          display: flex;
          align-items: center;

          button {
            height: 20px;
          }

          .colorSquare {
            width: 40px;
            height: 40px;
          }

          .logoImage {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }
    }

    .errorWrapper {
      text-align: right;
      padding-right: 12px;
    }

    .uploadError {
      font-size: var(--fs-5);
      line-height: 120%;
      color: #ff3333;
    }
  }

  .modalContainer {
    background: #fff;
    color: #000000;

    .body {
      border-bottom: 1px solid #e5e5e5;

      .modalTitleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h6 {
          margin-bottom: 0;
        }

        span {
          margin-top: 2px;
          margin-left: 4px;
          font-size: var(--fs-5);
        }
      }

      h6 {
        font-size: var(--fs-1);
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        font-size: var(--fs-3);
        margin: 0;
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      &.right {
        grid-template-columns: 1fr auto;
      }

      & > div,
      a {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
      }

      & > div {
        width: 100%;
        margin: 0;

        a,
        button {
          width: 100%;
        }
      }
    }
  }
`;
