import styled from 'styled-components';

export const ReviewTagsStyles = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    gap: 15px;

    .selectWrapper {
        display: flex;
        flex-direction: column;

        .selectField {
            flex-grow: 1;
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(229, 229, 229);
            box-sizing: border-box;
            border-radius: 4px;
            padding: 0px 16px;
            position: relative;
            cursor: pointer;

            .selectValue {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                height: 100%;

                .placeholder {
                    font-size: var(--fs-3);
                    color: #6c6c6c;
                }

                .active {
                    transform: rotate(180deg);
                }
            }

            .selectOptions {
                background-color: rgb(255, 255, 255);
                border: 1px solid rgb(229, 229, 229);
                box-sizing: border-box;
                border-radius: 4px;
                position: absolute;
                top: calc(100% + 10px);
                left: 0px;
                z-index: 100;
                width: 100%;
                overflow-y: auto;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px;
                max-height: 200px;

                .selectOption {
                    .selectRow {
                        width: 100%;
                        min-height: 32px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid rgb(196, 196, 196);
                        box-sizing: border-box;
                        padding-right: 16px;

                        span {
                            color: rgb(110, 110, 110);
                        }
                    }

                    .selectCheckbox {
                        display: flex;
                        align-items: center;
                        gap: 7px;

                        .selectMarker {
                            cursor: pointer;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 22px;
                            height: 22px;
                            background-color: rgb(255, 255, 255);
                            border: 1px solid rgb(229, 229, 229);
                            box-sizing: border-box;

                            &.selectActive {
                                border: 1px solid rgb(27, 202, 185);
                            }
                        }
                    }
                }
            }
        }
    }
`;
