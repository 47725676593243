import React, { FC, useEffect } from 'react';
import { CheckMark } from '../../assets';
import { CheckboxStyles } from './styles';
import { CheckboxProps } from './types';
export const CheckboxItem: FC<CheckboxProps> = ({
  selected,
  hasSelectedChildren,
  allChildrenAreSelected,
  handleOnClick,
  hasLabel,
  disabled
}) => {
  useEffect(() => {}, []);
  return <CheckboxStyles style={hasLabel ? {
    marginTop: 16
  } : {}}>
      <div className={selected || hasSelectedChildren ? 'default selected' : 'default'} onChange={disabled ? () => null : handleOnClick}>
        {(selected || allChildrenAreSelected) && <CheckMark />}
        {!selected && !allChildrenAreSelected && hasSelectedChildren && <div className="square" />}
      </div>
    </CheckboxStyles>;
};