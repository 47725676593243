import styled from 'styled-components';

export const QuarterFilterStyles = styled.div`
  background: #fff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  gap: 10px;
  font-family: 'e-Ukraine', sans-serif !important;

  @media screen and (max-width: 1440px) {
    padding: 5px 12px;
  }

  .list {
    padding-left: 10px;
  }

  h4 {
    @media screen and (max-width: 1440px) {
      font-size: var(--fs-3);
    }
  }

  .year {
    &-label {
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 8px;

      @media screen and (max-width: 1440px) {
       font-size: var(--fs-3);
      }
    }

    &-arrow {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    &-active {
      transform: rotate(180deg);
    }

    &-checkmark {
      width: 22px;
      height: 22px;
      border: 1px solid rgb(229, 229, 229);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media screen and (max-width: 1440px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  .quarters {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 4px;
  }

  .quarter {
    display: flex;
    align-items: center;
    gap: 8px;

    &-checkmark {
      width: 18px;
      height: 18px;
      border: 1px solid rgb(229, 229, 229);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media screen and (max-width: 1440px) {
        width: 14px;
        height: 14px;
      }
    }

    p {
      font-size: var(--fs-5);
      color: #888;
    }
  }
`;
