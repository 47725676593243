import React, { FC } from 'react';
export const CheckMark: FC<{
  width?: number;
  height?: number;
  color?: string;
}> = React.memo(({
  width = 18,
  height = 14,
  color = '#000'
}) => <svg width={width} height={height} fill="none" viewBox="0 0 18 14">
    <path fill={color} d="M5.965 10.44L1.967 6.294.25 8.074 5.965 14 17.75 1.78 16.033 0 5.965 10.44z" />
  </svg>);