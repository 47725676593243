import styled from 'styled-components';

export const CsiDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* overflow: hidden; */

  .csi {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    padding-top: 25px;

    @media screen and (max-width: 1440px) {
      gap: 30px;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex: 1;

      h5 {
        font-weight: 400;
        font-size: var(--fs-3);
        line-height: 115%;
        text-align: center;
        color: #000000;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-5);
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      width: calc(20% - 10px);

      @media screen and (max-width: 1440px) {
        gap: 15px;
      }
    }

    &-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      border-bottom: 1px solid #dee6eb;

      h3 {
        width: 33%;
        background: #cfc4ff;
        border-radius: 10px 10px 0px 0px;
        position: relative;

        span {
          width: 100%;
          text-align: center;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translate(-50%);

          font-weight: 700;
          font-size: var(--fs-2);
          line-height: 115%;
          color: #000000;
          opacity: 0.8;

          @media screen and (max-width: 1440px) {
            font-size: var(--fs-5);
          }
        }
      }
    }

    &-pie {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding: 35px 14px;
      border: 1px solid #dee6eb;
      border-radius: 10px;
      position: relative;

      @media screen and (max-width: 1440px) {
        padding: 20px 14px;
      }
    }

    &-legend {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 3px;

      &:nth-child(1) {
        right: 15px;
        bottom: 15px;

        @media screen and (max-width: 1440px) {
          right: 5px;
          bottom: 5px;
        }
      }

      &:nth-child(2) {
        bottom: 15px;
        left: 15px;

        @media screen and (max-width: 1440px) {
          bottom: 5px;
          left: 5px;
        }
      }

      &:nth-child(4) {
        right: 15px;
        top: 15px;

        @media screen and (max-width: 1440px) {
          right: 5px;
          top: 5px;
        }
      }

      &:nth-child(3) {
        left: 15px;
        top: 15px;

        @media screen and (max-width: 1440px) {
          left: 5px;
          top: 5px;
        }
      }

      p {
        font-weight: 400;
        font-size: var(--fs-5);
        line-height: 115%;
        color: #000000;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-7);
        }
      }

      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }

    &-labels {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      @media screen and (max-width: 1440px) {
        gap: 15px;
      }
    }

    &-label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      p {
        font-weight: 400;
        font-size: var(--fs-3);
        line-height: 115%;
        color: #000000;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-5);
        }
      }

      span {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        @media screen and (max-width: 1440px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
`;
