import styled from 'styled-components';

export const TagStyles = styled.div`
    padding: 5px 12px 5px 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 10px;
    border-bottom: 1px solid #f0f0f0;

    svg {
        flex-shrink: 0;
    }

    .expand {
        position: relative;
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;

        &.active {
            transform: rotate(0deg);
        }
    }

    .tag {
        display: flex;
        align-items: center;
        gap: 25px;

        &-name {
            font-size: var(--fs-3);
            font-weight: 700;
        }

        &-utils {
            align-items: center;
            gap: 10px;
            display: flex;
        }
    }
`;
