import styled from 'styled-components';

export const InformationRespondentsDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;

  .charts {
    height: 100%;
    width: 100%;
    display: grid;
    max-width: 1400px;
    aspect-ratio: 4 / 3;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 24px;
    grid-template-areas:
      'one one one one two two two two three three three three'
      'one one one one two two two two three three three three'
      'four four four four four four four four five five five five'
      'four four four four four four four four five five five five';

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1440px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: var(--fs-1);
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-7);
          padding-bottom: 5px;
        }
      }
    }

    &-one {
      grid-area: one;
    }

    &-two {
      grid-area: two;
    }

    &-three {
      grid-area: three;
    }

    &-four {
      grid-area: four;
    }

    &-five {
      grid-area: five;
    }
  }

  .bars {
    display: flex;
    align-items: flex-end;
    gap: 35px;
    flex: 1;

    @media screen and (max-width: 1440px) {
      gap: 8px;
    }

    &-item {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      @media screen and (max-width: 1440px) {
        gap: 8px;
      }

      h6 {
        font-weight: 400;
        font-size: var(--fs-7);
        line-height: 115%;
        text-align: center;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 64px;
      }
    }

    &-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-end;
      gap: 5px;
      width: 100%;
      height: 100%;
      max-width: 200px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 115%;
        height: 1px;
        bottom: 0;
        transform: translate(-50%);
        left: 50%;
        background: #dee6eb;
      }
    }

    &-fill {
      flex: 1;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 200px;

      div {
        border-radius: 100px 100px 0 0px;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
      }

      span {
        font-weight: 700;
        font-size: var(--fs-2);
        line-height: 115%;
        color: #000000;
        opacity: 0.8;
        position: absolute;
        z-index: 3;
        transform: translate(-50%, -50%) rotate(-90deg);
        bottom: 30px;
        left: 50%;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-7);
          bottom: 10px;
        }
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 120px;

      p {
        font-weight: 400;
        font-size: var(--fs-5);
        line-height: 115%;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
      }

      div {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
    }
  }

  .pies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
  }
`;
