import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { SearchIcon } from '../../../../assets';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { DeepArrayItem } from '../../../../entities/DeepArrayItem';
import { filterDeepObjectArray, hasNonEmptyChildren } from '../../../../utils';
import { useAppSelector } from '../../../../state';
import { MultiSelectWithChildrenStyles } from './MultiSelectWithChildrenStyles';
import MultiSelectItem from '../multiSelectItem/MultiSelectItem';
import { expandAllSelectedItemsAndTheirParents, expandSearchedItemsAndTheirParents } from './utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { FormErrorMessage, Loader } from '../../../atoms';
import { MultiSelectWithChildrenProps } from './types';
export default function MultiSelectWithChildren({
  label,
  name,
  options,
  selectKey,
  property,
  handleSelect,
  formGroupStyles,
  optionsContainerStyles,
  search,
  hideError,
  selectError,
  selectErrorName,
  required,
  isLoading,
  disabled,
  showLastElement
}: MultiSelectWithChildrenProps) {
  const {
    errors,
    touched
  } = useFormikContext();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [open, setOpen] = useState(true);
  const [searchParams, setSearchParams] = useState<string>('');
  const [itemsExpanded, setItemsExpanded] = useState<{
    [key: number]: boolean;
  }>({});
  const [itemsFiltered, setItemsFiltered] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const itemsExpandedRef = useRef<{
    [key: number]: boolean;
  }>({});
  const itemsFilteredRef = useRef<DeepArrayItem[]>([]);
  const debouncedSearchParams = useDebounce(searchParams, 1500);
  function addToExpandedItemsList(id: number) {
    setItemsExpanded({
      ...itemsExpandedRef.current,
      [id]: !itemsExpandedRef.current[id]
    });
    itemsExpandedRef.current = {
      ...itemsExpandedRef.current,
      [id]: !itemsExpandedRef.current[id]
    };
  }
  function clearExpandedItemsList() {
    setItemsExpanded({});
    itemsExpandedRef.current = {};
  }
  useEffect(() => {
    itemsFilteredRef.current = options;
    setItemsFiltered(!itemsFiltered);
  }, [options]);
  const handleExpandRow = React.useCallback((id: number) => {
    console.log('expanding');
    if (itemsExpandedRef.current) {
      addToExpandedItemsList(id);
    }
  }, []);
  useEffect(() => {
    if (options) {
      if (search && debouncedSearchParams) {
        const itemsTree = filterDeepObjectArray(options, debouncedSearchParams, selectKey);
        itemsFilteredRef.current = itemsTree;
        setItemsFiltered(!itemsFiltered);
        clearExpandedItemsList();
        expandSearchedItemsAndTheirParents(itemsTree, addToExpandedItemsList);
      } else {
        itemsFilteredRef.current = [...options];
        setItemsFiltered(!itemsFiltered);
        clearExpandedItemsList();
        expandAllSelectedItemsAndTheirParents(options, addToExpandedItemsList);
      }
    }
  }, [debouncedSearchParams]);
  useEffect(() => {
    setSearchParams('');
  }, [open]);
  function handleSelectItem(value: any, path?: number[]) {
    handleSelect(value, path || undefined, !!searchParams);
    setSelected(!selected);
  }
  return <MultiSelectWithChildrenStyles>
      <div className={`form-group${
    // @ts-ignore
    touched[name] && !errors[name] ? ' valid' // @ts-ignore
    : touched[name] && errors[name] ? ' error' : ''}`} style={formGroupStyles || {}}>
        {label && <div className="labelContainer">
            <label htmlFor={name}>
              {label}
              {required && <span className="required"> *</span>}
            </label>
          </div>}
      </div>
      {(isLoading || disabled) && <div className="loading">
          {isLoading && <Loader margin={0} height={20} />}
        </div>}
      {open && <div className={`options${optionsContainerStyles ? ` ${optionsContainerStyles}` : ''}`} ref={optionsListRef}>
          {search && <div className="searchWrapper">
              <SearchIcon />
              <input type="text" value={searchParams} onChange={e => setSearchParams(e.target.value)} placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} />
            </div>}
          {itemsExpandedRef.current && itemsFilteredRef.current.sort((a, b) => a.name.localeCompare(b.name)).map((option, index) => {
        const visibleArrow = showLastElement || hasNonEmptyChildren(option.children);
        return <MultiSelectItem
        // @ts-ignore
        showLastElement={showLastElement} key={`itemKey:${[index]}`} path={[index]} option={option} selectKey={selectKey} childrenKey="children" inputName={name} expandedRows={itemsExpandedRef.current} expandable={showLastElement || visibleArrow && option.children.length > 0} handleExpandRow={handleExpandRow} imageProperty={property} handleSelect={handleSelectItem} setOpen={setOpen} selectable checkboxSelected={option.selected} checkboxHasSelectedChildren={option.hasSelectedChildren} checkboxAllChildrenSelected={option.allChildrenAreSelected} />;
      })}
        </div>}

      {!hideError && <div className="selectErrorContainer">
          {selectError && <span>{selectError}</span>}
          {!selectError && touched && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
        </div>}
    </MultiSelectWithChildrenStyles>;
}