import { AcessRequestStyles, SearchContainer } from './AcessRequestStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, { useEffect, useRef, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { ApiResponse, companyApi, rolesApi, usersApi } from '../../api';
import { availablePageSizes, hiddenReviewFilterStatuses, reviewsStatusIconsAndText } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { accessesApi, AccessRequest } from '../../api/accessesApi/Accesses';
import format from 'date-fns/format';
import { CompanyFilter, FilterSelectInput, Loader, SubHeader, Table, ToggleSwitcher, TransparentButton } from '../../components';
import { CompaniesMainInfo, ICompanyUser, IOption, IReviewStatus, IUser } from '../../entities';
import { DropdownFilter } from '../../components/molecules/dropDownFilter';
import { FilterSelectInputStyles } from '../../components/molecules/reviewsTableFilters/filterSelectInput/FilterSelectInputStyles';
import { ArrowDownIcon, SearchIcon, TableFilterIcon } from '../../assets';
import { useClickOutside, useDebounce } from '../../hooks';
import { proxyFile } from '../../utils';
import { BsSortNumericDown } from 'react-icons/bs';
import { debounce, filter } from 'lodash';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { TOption } from '../../components/molecules/reviewsTableFilters/filterSelectInput/initialValues';
import { CustomFilter } from '../../components/molecules/customFilter';
import { Chip } from '../../components/atoms/chip';
const sortingVariants = ['asc', 'desc'];
export const initialServicesTypeValues = [{
  id: 'pending',
  value: false,
  name: 'Очікує'
}, {
  id: 'approved',
  value: false,
  name: 'Підтверджено'
}, {
  id: 'rejected',
  value: false,
  name: 'Відхилено'
}];
const statuses: {
  [key: string]: string;
} = {
  pending: 'Очікує',
  approved: 'Підтверджено',
  rejected: 'Відхилено'
};
const statusesColors: {
  [key: string]: string;
} = {
  pending: '#6c6c6c',
  approved: '#6c6c6c',
  rejected: '#6c6c6c'
};
function NameCustomField({
  name,
  logo,
  textColor
}: {
  name: string;
  logo?: string;
  textColor?: string;
}) {
  return <div style={{
    marginTop: 7,
    marginBottom: 7
  }} className="customNameWrapper">
      {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
          <div className="image">
            {logo && <img src={proxyFile(logo, true)} alt="logo" />}
          </div>
        </div>}
      <span style={{
      color: textColor || '#000'
    }}>{name}</span>
    </div>;
}
export const AccessRequests = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const isManager = true;
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const filtersRef = useRef<any>();
  const shouldBeCanceled = useRef<boolean>(false);
  const accessesRef = useRef<AccessRequest[]>();
  const itemsQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[1]);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[] | TOption[];
  }>({
    createdAt: sortingVariants[0] // 'asc', 'desc', ''
  });
  useClickOutside(filtersRef, () => setIsFilterOpened(false));
  function workWithResponse(response: ApiResponse<AccessRequest[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newItems = response.data;
      if (!shouldBeCanceled.current) {
        accessesRef.current = [...(Array.isArray(newItems) ? newItems : [])];
        itemsQuantityRef.current = response.count!;
        setUploading(false);
      }
    }
  }
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;
      return acc;
    }, {});
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: compID
      };
      navigate('/access_requests/manage/page/1');
      setReQuery(!reQuery);
    }
  };
  const [initialFilterValues, setInitialFilterValues] = useState<{
    managers: TOption[];
    roles: TOption[];
    workers: TOption[];
  }>({
    managers: [],
    roles: [],
    workers: []
  });
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    accessesApi.accessReviewFiltersPost().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setInitialFilterValues(() => ({
          managers: Object.keys(res.data.managers).map(key => ({
            id: key,
            name: res.data.managers[key],
            value: false
          })),
          workers: Object.keys(res.data.workers).map(key => ({
            id: key,
            name: res.data.workers[key],
            value: false
          })),
          roles: res.data.roles.map(e => ({
            id: e,
            value: false,
            name: e
          }))
        }));
        // getCompanies(res.data);
      }
    });
  }, []);
  const fetchData = async () => {
    setUploading(true);
    accessesRef.current = undefined;
    const sorting = [];
    if (filterParamsRef.current.createdAt) {
      sorting.push(`createdAt:${filterParamsRef.current.createdAt}`);
    }
    const accesses = await accessesApi.accessReviewListPost({
      query: searchQuery as string,
      sorting,
      compayIDs: [+filterParamsRef.current.companyID as number],
      status: (filterParamsRef.current.statuses as TOption[])?.map((e: TOption) => e.id),
      nodesIDs: filterParamsRef.current.nodes as number[],
      workerIDs: (filterParamsRef.current.name as TOption[])?.map((e: TOption) => +e.id),
      managerIDs: filterParamsRef.current.responsible_name as number[],
      roles: (filterParamsRef.current.role as TOption[])?.map((e: TOption) => e.id),
      offset: (pageNumber ? +pageNumber - 1 : 0) * pageSizeRef.current,
      limit: pageSizeRef.current,
      isManager
    });
    workWithResponse({
      statusCode: accesses.statusCode,
      count: accesses.data.count,
      data: accesses.data.requests
    });
  };
  console.log(initialFilterValues);
  useEffect(() => {
    if (!uploading && filterParamsRef.current.companyID) {
      fetchData();
    }
  }, [pageNumber, reQuery, isManager, filterParamsRef.current]);
  const applyFilters = (params: any) => {
    filterParamsRef.current = params;
    setReQuery(e => !e);
  };
  const debouncedInput = useDebounce(searchQuery, 1000);
  useEffect(() => {
    setReQuery(e => !e);
  }, [debouncedInput]);
  const setFilterParams = (params: any) => {
    filterParamsRef.current = params;
    setIsFilterOpened(false);
  };
  const approveRequest = async (id: number) => {
    const res = await accessesApi.approveAccessRequest(id);
    setReQuery(e => !e);
  };
  const rejectRequest = async (id: number) => {
    const res = await accessesApi.rejectAccessRequest(id);
    setReQuery(e => !e);
  };
  const reviewStatuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter(item => !hiddenReviewFilterStatuses.includes(item?.value));
  const getUsers = async (limit: number, offset: number, q: string) => {
    const data = await usersApi.getFilteredUsers({
      q
    }, offset, limit);
    return {
      items: data.data.map(e => ({
        value: false,
        name: e.name,
        id: String(e.id) ?? '1'
      })),
      count: data.count ?? 0
    };
  };
  useEffect(() => {
    if (accessesRef.current) {
      setTableHeaders([{
        Header: 'ID',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.id} textColor="#6c6c6c" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter resizable search dropdownWidth={300} getPaginatedInitialValues={getUsers} filterParams={filterParamsRef.current as any} applyFilters={params => {
          applyFilters({
            ...filterParamsRef.current,
            ...params
          });
          // app;
        }} title="Users" name="name" />
        // <FilterSelectInput
        //   filterWidth={200}
        //   filtersItemsWidth={300}
        //   search
        //   initiaFilterlValues={initialFilterValues.workers}
        //   autoSelectChildren={false}
        //   resizable
        //   title="Від кого"
        //   type="checkbox"
        //   columnName="name"
        //   filterParams={filterParamsRef.current}
        //   setFilterParams={setFilterParams}
        //   setReQuery={() => setReQuery(!reQuery)}
        //   setApply={applyFilters}
        //   containerExtraStyles="extraFilterStyles"
        //   extraFilterFormStyles="extraFilterFormStyles"
        //   optionsExtraStyles="optionsExtraStyles"
        // />
        ,
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.worker.name} textColor="#6c6c6c" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <FilterSelectInput filterWidth={200} filtersItemsWidth={300} search initiaFilterlValues={initialFilterValues.managers} autoSelectChildren={false} resizable title="Кому" type="checkbox" columnName="responsible_name" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'responsible_name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.manager.name} textColor="#6c6c6c" />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <div onClick={() => {
          // console.log({ cr: filterParamsRef.current });
          const nextIndex = sortingVariants.findIndex(e => e === filterParamsRef.current.createdAt as string);
          const index = nextIndex === -1 || nextIndex > sortingVariants.length ? 0 : nextIndex + 1;
          applyFilters({
            ...filterParamsRef.current,
            createdAt: sortingVariants[index]
          });
        }} className={`sortable ${filterParamsRef.current.createdAt === 'asc' ? 'asc' : filterParamsRef.current.createdAt === 'desc' ? 'desc' : ''}`}>
              <p>Дата створення</p>
              {['desc', 'asc'].includes(filterParamsRef.current.createdAt as string) ? <ArrowDownIcon /> : <BsSortNumericDown size={25} />}
            </div>,
        accessor: 'createdAt',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={format(new Date(data.row.original.createdAt), 'dd.MM.yyyy HH:mm')} textColor="#6c6c6c" />
      }, {
        // @ts-ignore
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <CustomFilter resizable openerWidth={200} dropdownWidth={350} filterParams={filterParamsRef.current as any} applyFilters={params => {
          applyFilters({
            ...filterParamsRef.current,
            ...params
          });
          // app;
        }} title={getTranslationByLangOrEng(interfaceLanguage, 'table_columns_settings_status_option')} name="statuses" initialValues={initialServicesTypeValues} />

        // <FilterSelectInputStyles ref={filtersRef}>
        //   <div className="filterRow">
        //     <div
        //       // style={filtersOpenerStyles}
        //       className={`filtersOpener ${
        //         isFilterOpened ? 'filtersOpener-active' : ''
        //       }`}
        //       onClick={() => {
        //         setIsFilterOpened(!isFilterOpened);
        //       }}
        //     >
        //       <span className="filterTitle">Статус</span>
        //       <ArrowDownIcon />
        //     </div>
        //     {/* @ts-ignore */}
        //     {filterParamsRef?.current?.statuses?.length && (
        //       <TableFilterIcon width={15} height={15} />
        //     )}
        //   </div>
        //   {isFilterOpened && (
        //     <div style={{ width: 200, position: 'absolute', top: 30 }}>
        //       <DropdownFilter
        //         name="statuses"
        //         initialValues={initialServicesTypeValues}
        //         filterParams={filterParamsRef.current}
        //         setFilterParams={setFilterParams}
        //         setReQuery={() => setReQuery((e) => !e)}
        //         // @ts-ignore
        //         setApply={applyFilters}
        //         // @ts-ignore
        //         onClose={() => setIsFilterOpened(false)}
        //       />
        //     </div>
        //   )}
        // </FilterSelectInputStyles>
        // <FilterSelectInput
        //   columnName="statuses"
        //   title="Статус"
        //   filterParams={filterParamsRef.current}
        //   setFilterParams={setFilterParams}
        //   setReQuery={() => setReQuery(!reQuery)}
        //   setApply={applyFilters}
        //   status={(reviewStatuses as IReviewStatus[]) || []}
        // />
        ,
        accessor: 'status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={statuses[data.row.original.status]} textColor={statusesColors[data.row.original.status]} />
      }, {
        Header: <FilterSelectInput resizable title="Фронт-офіс" columnName="nodes" filterParams={filterParamsRef.current} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} containerExtraStyles="extraFilterStyles" extraFilterFormStyles="extraFilterFormStyles" optionsExtraStyles="optionsExtraStyles" />,
        accessor: 'nodes',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) =>
        // @ts-ignore
        <div style={{
          paddingTop: 5,
          paddingBottom: 5
        }}>
              {row.original.nodes.map((e: any) => <NameCustomField name={` - ${e.map((e: any) => e.name).join(', ')}`} textColor="#6c6c6c" />)}
            </div>
      }, {
        Header: 'Посада',
        accessor: 'position',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) =>
        // @ts-ignore
        // <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        //   {row.original.nodes.map((e: any) => (
        <NameCustomField name={row.original.worker?.position} textColor="#6c6c6c" />
        //   ))}
        // </div>
      }, {
        Header: <CustomFilter search resizable
        // openerWidth={200}
        dropdownWidth={350} filterParams={filterParamsRef.current as any} applyFilters={params => {
          applyFilters({
            ...filterParamsRef.current,
            ...params
          });
          // app;
        }} title="Роль" name="role" initialValues={initialFilterValues.roles} />
        // <FilterSelectInput
        //   // extraStyles={{ width: 200 }}
        //   filterWidth={200}
        //   filtersItemsWidth={350}
        //   search
        //   type="checkbox"
        //   initiaFilterlValues={initialFilterValues.roles}
        //   resizable
        //   title="Роль"
        //   columnName="role"
        //   filterParams={filterParamsRef.current}
        //   setFilterParams={setFilterParams}
        //   setReQuery={() => setReQuery(!reQuery)}
        //   setApply={applyFilters}
        //   containerExtraStyles="extraFilterStyles"
        //   extraFilterFormStyles="extraFilterFormStyles"
        //   optionsExtraStyles="optionsExtraStyles"
        // />
        ,
        accessor: 'role',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) =>
        // @ts-ignore
        // <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        //   {row.original.nodes.map((e: any) => (
        <NameCustomField name={row.original.role} textColor="#6c6c6c" />
        //   ))}
        // </div>
      }, {
        Header: 'Опрацьовано',
        accessor: 'processedBy',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => row.original.processedBy ? <NameCustomField name={row.original.processedBy.name} textColor="#6c6c6c" /> : <div />
      }, {
        Header: '',
        accessor: 'accept',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => data.row.original.status === 'pending' ? <SubmitButton extraButtonStyles={{
          backgroundColor: '#04c65d',
          height: 32,
          paddingRight: 15,
          paddingLeft: 15
        }} onClick={() => approveRequest(data.row.original.id)}>
              <p>Підтвердити</p>
            </SubmitButton> : <div />
      }, {
        Header: '',
        accessor: 'reject',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => row.original.status === 'pending' || row.original.status === 'approved' ? <SubmitButton extraButtonStyles={{
          backgroundColor: '#000',
          height: 32,
          paddingRight: 15,
          paddingLeft: 15
        }} onClick={() => rejectRequest(row.original.id)}>
                <p>Відхилити</p>
              </SubmitButton> : <div />
      }]);
    }
  }, [accessesRef.current, reQuery, isFilterOpened, filterParamsRef.current
  // users,
  ]);
  const extraFieldsSettings = {
    editButtons: {
      justifyContent: 'flex-end'
    }
  };
  // const onClickToRow = async (data: any) => {};
  // console.log({ param: filterParamsRef.current });

  const removeFilterById = (id: string | number, name: string) => {
    applyFilters({
      ...filterParamsRef.current,
      [name]: (filterParamsRef.current[name] as TOption[])?.filter(e => String(e.id) !== String(id))
    });
  };
  return <AcessRequestStyles>
      <Helmet>
        <title>Надання доступів</title>
      </Helmet>
      <SubHeader title={`Запити на доступ (${itemsQuantityRef.current})`}>
        {companies && <CompanyFilter extraStyles={{
        width: 200,
        maxWidth: 300
      }} filterParams={filterParamsRef.current} companies={companies} setFilterParams={applyFilters} setReQuery={() => setReQuery(!reQuery)} />}
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/users')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>
      {filterParamsRef.current.companyID && <SearchContainer>
          <input placeholder="Пошук..." type="text" value={searchQuery as string} onChange={({
        target: {
          value
        }
      }) => {
        setSearchQuery(value);
      }} />
        </SearchContainer>}
      <div className="access-wrapper">
        {uploading && <Loader />}
        {(filterParamsRef.current.name as TOption[])?.map(e => <Chip id={e.id} text={e.name} onPress={() => {
        removeFilterById(e.id, 'name');
      }} />)}
        {!!accessesRef.current && !!tableHeaders && !uploading && filterParamsRef.current.companyID && <Table
      // sortableColumns={['date']}
      fixedHeader pagination columns={tableHeaders} data={accessesRef.current} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings}
      // headerColumnCounts={{ role: itemsQuantityRef.current }}
      rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_role_title')} hiddenHeaders={['editButtons']} fullWidthColumns={['editButtons']} pageSize={pageSizeRef.current} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={itemsQuantityRef.current} pageNumberHandler={page => {
        if (page) {
          navigate(`/access_requests/manage/page/${page}`);
        }
      }} />}
      </div>
    </AcessRequestStyles>;
};