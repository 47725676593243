import styled from 'styled-components';

export const QRPublishingNodesDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .bars {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 24px;

    &-item {
      width: calc(50% - 12px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 8px;
      border: 1px solid #dee6eb;
      border-radius: 10px;

      @media screen and (max-width: 1440px) {
        padding: 12px;
      }

      h4 {
        font-weight: 400;
        font-size: var(--fs-1);
        line-height: 115%;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee6eb;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-5);
          padding-bottom: 5px;
        }
      }
    }
  }

  .chart {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;

      h6 {
        width: calc(30% - 8px);
        font-weight: 400;
        font-size: var(--fs-5);
        line-height: 115%;
        color: #000000;
        text-align: end;

        @media screen and (max-width: 1440px) {
          font-size: var(--fs-7);
        }
      }
    }

    &-fill {
      width: calc(70% - 8px);
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% + 8px);
        top: 50%;
        transform: translate(0, -50%);
        background: #dee6eb;
      }

      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 0px 100px 100px 0px;
      }

      span {
        font-weight: 700;
        font-size: var(--fs-7);
        line-height: 115%;
        text-align: center;
        color: #000000;
        opacity: 0.8;
        padding: 4px 8px;
        position: relative;
        z-index: 3;
      }
    }
  }
`;
