import styled from 'styled-components';

export const CalendarFilterStyles = styled.div<{ tableVersion: boolean }>`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: ${({ tableVersion }) => (tableVersion ? '8px' : '16px')};
  position: absolute;
  display: flex;
  top: 100%;
  left: 100%;
  transform: translate(-50%);
  background: #fff;
  flex-direction: column;
  border-radius: 4px;
  z-index: 9999;

  .calendars {
    display: flex;
    flex-direction: ${({ tableVersion }) => (tableVersion ? 'column' : 'row')};
    gap: ${({ tableVersion }) => (tableVersion ? '8px' : '20px')};
    margin-bottom: 10px;

    .calendar {
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        margin-bottom: 8px;
      }

      .react-calendar {
        width: 250px;
      }

      .react-calendar__navigation button {
        min-width: 32px;
      }
    }
  }

  .templates {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;

    button {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(0, 0, 0);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 0;
      transition: all 0.3s ease 0s;
      width: 30%;

      &:disabled,
      &:hover {
        background: rgb(0, 0, 0);
        color: #fff;
      }
    }
  }

  .submitButton,
  .resetButton {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    font-weight: 500;
    font-size: var(--fs-3);
    color: #ffffff;
  }

  .submitButton {
    background: #000;
    border: 1px solid #000;
  }

  .resetButton {
    background: #ccc;
  }

  .calendarFromToTabs {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;

    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #fff;
      margin-right: 32px;

      font-size: var(--fs-3);
      line-height: 120%;
      color: #7c7878;
      transition: all 0.3s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-bottom: 3px solid #000;
        color: #000;
        transition: all 0.3s;
      }
    }
  }
`;
