import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { ArrowDownIcon, SearchIcon } from '../../assets';
// import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { SquareCheckbox, FormErrorMessage, Loader } from '..';
import { CustomSelectWithMultipleCheckboxesStyles } from './CustomSelectWithMultipleCheckboxesStyles';
import { SelectProps } from './types';
import { useClickOutside } from '../../hooks/useClickOutside';
import { isString } from 'lodash';
// import { useAppSelector } from '../../../state';
// import { reviewsStatusIconsAndText } from '../../../constants';

export const CustomSelectWithMultipleCheckboxes: React.FC<SelectProps> = ({
  label,
  name,
  options,
  placeholder,
  valueKey,
  formGroupStyles,
  optionsContainerStyles,
  optionTextKey,
  search,
  hideError,
  selectError,
  selectErrorName,
  handleChange,
  extraOneOptionStyles,
  placeholderStyles,
  oneOptionValueStyles,
  disabled,
  isLoading,
  error,
  alwaysOpened,
  disableScroll,
  // search
  isOffliteSearch,
  searchFieldPlaceholder,
  searchQuery,
  setSearchQuery
}) => {
  const {
    errors,
    touched
  } = useFormikContext();

  // const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState(false);
  // const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   setSearchParams('');
  // }, [open]);
  // console.log({ searchQuery, setSearchQuery });
  // console.log();

  useClickOutside(optionsListRef, () => {
    if (!alwaysOpened) {
      setOpen(false);
    }
  }, selectedValueFieldRef.current);
  return <CustomSelectWithMultipleCheckboxesStyles disableScroll={disableScroll}>
      <div className={`form-group${
    // @ts-ignore
    touched[name] && !errors[name] ? ' valid' // @ts-ignore
    : touched[name] && errors[name] ? ' error' : ''}`} style={formGroupStyles || {}}>
        {label && <div className="labelContainer">
            <label htmlFor={name}>{label}</label>
          </div>}
        <div className="mainBodyWrapper">
          {!alwaysOpened && <div className="selectField">
              <div className="selectedValue" ref={selectedValueFieldRef} onClick={() => disabled || isLoading ? null : setOpen(!open)}>
                <div className="valueContainer">
                  <span style={placeholderStyles}>{placeholder}</span>
                  <div className={open ? 'arrowDownActive' : ''}>
                    <ArrowDownIcon />
                  </div>
                </div>
              </div>
            </div>}
          {(isLoading || disabled) && <div className="loading">
              {isLoading && <Loader margin={0} height={20} />}
            </div>}
          {!hideError && <div className="selectErrorContainer">
              {selectError && <span>{selectError}</span>}
              {!selectError && touched && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
            </div>}
          {(alwaysOpened || open) && <div className={`options${alwaysOpened ? ' alwaysOpened' : ''}`} style={optionsContainerStyles} ref={optionsListRef}>
              {!!(isString(searchQuery) && setSearchQuery && search) && <div className="searchWrapper">
                  <SearchIcon />
                  <input type="text" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder={searchFieldPlaceholder} />
                </div>}
              <FieldArray name="languages" render={arrayHelpers =>
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
                    {options.length > 0 && options.filter(e => isOffliteSearch ? e.name.toLowerCase().includes(searchQuery?.toLowerCase()) : true).map((field, index) => {
              // const Icon = name === 'status'
              //   && field
              //   && field?.hasOwnProperty('icon')
              //   ? reviewsStatusIconsAndText(interfaceLanguage)[
              //     field?.id
              //   ]?.icon
              //   : null;
              const Icon = field.icon;
              // const NetworkIcon = name === 'networks' && field.icon;
              // const AttachmentIcon = name === 'attachment' && field.icon;
              return <div style={extraOneOptionStyles} className="option" key={field.name}>
                              <div className="row">
                                <SquareCheckbox name={valueKey ? `${name}[${index}][${valueKey}]` : `${name}[${index}].value`} value={valueKey ? options[index][valueKey] : options[index].value} onChange={handleChange} extraBlockStyles={{
                    width: '100%'
                  }} checkboxWrapperStyles={{
                    alignItems: 'center'
                  }}>
                                  <div style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                                    {Icon && <div className="iconWrapper">
                                        <Icon width={24} height={24} />
                                      </div>}
                                    {/* {NetworkIcon && (
                                     <div className="iconWrapper">
                                      <NetworkIcon width={20} height={20} />
                                     </div>
                                     )}
                                     {AttachmentIcon && (
                                     <div className="iconWrapper">
                                      <AttachmentIcon width={20} height={20} />
                                     </div>
                                     )} */}
                                    <span title={optionTextKey && field[optionTextKey] || field.name} style={oneOptionValueStyles}>
                                      {optionTextKey && field[optionTextKey] || field.name}
                                    </span>
                                  </div>
                                </SquareCheckbox>
                              </div>
                            </div>;
            })}
                  </>} />
            </div>}
        </div>
      </div>
    </CustomSelectWithMultipleCheckboxesStyles>;
};