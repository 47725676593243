export const Directions = ['asc', 'desc'];

export type TOption = {
  icon?: any;
  value: boolean;
  name: string;
  id: string;
};

export type TDropdownFilterValues = { [key: string]: TOption[] };

export type TCustomFilterProps = {
  sortable?: boolean;
  // filter state props
  // useNamesForFiltering?: boolean;
  filterParams: { [key: string]: TOption[] | string };
  applyFilters: (param: { [key: string]: any[] | string }) => void;
  additionalResetHandlers?: () => void;
  // search
  search?: boolean;
  // styles
  resizable?: boolean;
  // dropdown styles
  dropdownWidth?: number;
  openerWidth?: number;
  // labels
  title: string;
  name: string;
  resetFilterButtonText?: string;
  applyFiltersButtonText?: string;
  searchFieldPlaceholder?: string;
  // initial values without pagination
  initialValues?: TOption[];
  // pagination props
  limit?: number;
  getPaginatedInitialValues?: (
    limit: number,
    offset: number,
    q: string,
  ) => Promise<{ count: number; items: TOption[] }>;
};
