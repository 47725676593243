import styled from 'styled-components';

export const EditNodeStyles = styled.div`
  min-width: 750px;

  h2 {
    font-weight: 600;
    font-size: var(--fs-1);
    line-height: 23px;
    color: #000000;
  }

  .inputLabel,
  .labelContainer {
    .required {
      width: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .formSection {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .formSectionInnerWrap {
      margin: 20px 0;
      display: grid;
      gap: 60px;
      grid-auto-flow: row;
    }

    .labelContainer {
      display: flex;
      margin-bottom: 8px;

      .fakeLabel {
        font-weight: 500;
        font-size: var(--fs-2);
        line-height: 120%;
        color: #000000;
      }
    }

    h2 {
      margin: 15px 0;
    }

    .extraFieldsWrapper {
      max-width: 544px;
      .extraFieldContainer {
        position: relative;
        border-top: 1px solid #999;
        padding-top: 24px;

        .removeButtonContainer {
          position: absolute;
          top: calc(152px / 2 - 10px);
          right: -60px;
        }
      }

      .addFieldButtonContainer {
        margin-top: 10px;
        margin-bottom: 36px;
      }
    }
  }

  .inputWrap {
    flex-direction: row-reverse;
  }

  .extraFields {
    .extraFieldContainer {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: -8px;
      }

      .extraFieldButtons {
        display: flex;
        margin-left: 20px;

        .extraFieldsButtonContainer {
          height: 20px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .addFieldButtonContainer {
    margin-top: -8px;
    margin-bottom: 24px;
  }
`;
