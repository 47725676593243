import styled from 'styled-components';

export const ChipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 25px;
  font-size: var(--fs-3);
  color: #333;
  margin-right: 10px;
  margin-bottom: 10px;

  .chip-text {
    margin-right: 10px;
  }

  .chip-close {
    cursor: pointer;
    font-size: var(--fs-1);
  }

  /* Optional: Add a hover effect on the close icon */
  .chip-close:hover {
    color: #777;
  }
`;
